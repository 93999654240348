import { useEffect, useState, useRef } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'
import { fit_renta, fit_enganche, fit_residual } from '../../helpers/helper'
import { useCotizacion } from '../../hooks/useCotizacion'

function FirstTerm ( { valorFactura, residualPercent,residual2, setResidual2,setResidualPercent, enganchePercent, renta, setAportacionInicial, setRenta, aportacionInicial, setEnganchePercent, showInfo, rate, residualf5710,setResidualf5710} ) {
  const [showBtn, setShowBtn] = useState([false, false])
  
 
  return ( 
    <div className='mb-4'>
      <p className='bg-gray-200 rounded rounded-md grid content-center py-2 mx-2'>24 Meses</p>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full'>Renta</p>
        <InputNumber inputId='renta1' placeholder='24 Meses con Aportacion' mode="currency" currency="USD" locale="en-US" value={renta[0]}
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-2" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}
        onChange={(e) => {
          
          localStorage.setItem('renta_data', JSON.stringify([e.value, renta[1], renta[2]]))
         
          setRenta([e.value, renta[1], renta[2]])
          setShowBtn([false, true])
        }} />
        <button className={showBtn[0] ? 'bg-sky-500 rounded-md text-black w-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:sky-500 focus:ring-offset-2' : 'hidden'} 
        onClick={(e) => {
          e.preventDefault()
          setShowBtn([false, false, false])
          if( enganchePercent[0] > 35 ){
            showInfo(`La aportacion inicial no puede ser mayor a 35%`)
            setEnganchePercent([35, enganchePercent[1], enganchePercent[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([valorFactura*0.35, aportacionInicial[1], aportacionInicial[2]]));
            setAportacionInicial([valorFactura*0.35, aportacionInicial[1], aportacionInicial[2]])




            let renta_calculada = fit_renta(valorFactura - (valorFactura * (35 / 100)), (valorFactura * (residualPercent[0] / 100)), 24, rate)
            document.getElementById('renta1').value = renta_calculada

            localStorage.setItem('renta_data', JSON.stringify([renta_calculada, renta[1], renta[2]]))
            setRenta([renta_calculada, renta[1], renta[2]])
          }
          else if( enganchePercent[0] < 0 ){
            // console.log("acaaaaaaa7777777777777777777");
            showInfo(`La aportacion inicial no puede ser menor a 0%`)
            setEnganchePercent([0, enganchePercent[1], enganchePercent[2]])
            
            localStorage.setItem('apotacionInicial_data', JSON.stringify([valorFactura*0, aportacionInicial[1], aportacionInicial[2]]));
            setAportacionInicial([valorFactura*0, aportacionInicial[1], aportacionInicial[2]])

            let renta_calculada = fit_renta(valorFactura - (valorFactura * (0 / 100)), (valorFactura * (residualPercent[0] / 100)), 24, rate)
            document.getElementById('renta1').value = renta_calculada
            setRenta([renta_calculada, renta[1], renta[2]])
            localStorage.setItem('renta_data', JSON.stringify([renta_calculada, renta[1], renta[2]]))
          }
          else{
            // console.log("acaaaaaaaaaaportacuon inicial");
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (enganchePercent[0] / 100)), (valorFactura * (residualPercent[0] / 100)), 24, rate)
            
            localStorage.setItem('renta_data', JSON.stringify([renta_calculada, renta[1], renta[2]]))
            setRenta([renta_calculada, renta[1], renta[2]])
            document.getElementById('renta1').value = renta_calculada
          }
        }}>Ajustar</button>
      </aside>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full truncate'>Aportación Inicial</p>
        <InputNumber inputId='enganche1' placeholder='Aportacion Inicial' mode="currency" currency="USD" locale="en-US" value={valorFactura * (enganchePercent[0] / 100)}
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}
        onChange={(e) => {
          // console.log("aaaaaaaaaaaadondeeeee")
          setAportacionInicial([e.value, aportacionInicial[1], aportacionInicial[2]])
          localStorage.setItem('apotacionInicial_data', JSON.stringify([e.value, aportacionInicial[1], aportacionInicial[2]]));


          setEnganchePercent([(e.value*100)/valorFactura, enganchePercent[1], enganchePercent[2]])
          setShowBtn([true, false])
         }} />
        <InputNumber inputId="percent1" value={enganchePercent[0]} suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" 
        inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} onChange={ (e) => {
          setEnganchePercent([e.value, enganchePercent[1], enganchePercent[2]])
          
          setAportacionInicial([valorFactura * (e.value/100), aportacionInicial[1], aportacionInicial[2]])
          localStorage.setItem('apotacionInicial_data', JSON.stringify([valorFactura * (e.value/100), aportacionInicial[1], aportacionInicial[2]]));

          setShowBtn([true, false])
        } }/>
        <button className={showBtn[1] ? 'bg-sky-500 rounded-md text-white w-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:sky-500 focus:ring-offset-2' : 'hidden'} 
        onClick={(e) => {
          
          e.preventDefault()
          let result = fit_enganche(valorFactura, valorFactura * (residualPercent[0]/100), renta[0], 24, rate)
          setShowBtn([false, false, false])
          if((result*100)/valorFactura > 35){
            showInfo(`La aportacion inicial no puede ser mayor a 35%`)
            setAportacionInicial([valorFactura*0.35, aportacionInicial[1], aportacionInicial[2]])

            localStorage.setItem('apotacionInicial_data', JSON.stringify([valorFactura*0.35, aportacionInicial[1], aportacionInicial[2]]));



            setEnganchePercent([35, enganchePercent[1], enganchePercent[2]])
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (35 / 100)), (valorFactura * (residualPercent[0] / 100)), 24, rate)
            setRenta([renta_calculada, renta[1], renta[2]])
            localStorage.setItem('renta_data', JSON.stringify([renta_calculada, renta[1], renta[2]]))

            document.getElementById('renta1').value = renta_calculada
          }else if((result*100)/valorFactura < 0){
            showInfo(`La aportacion inicial no puede ser menor a 0%`)
            setAportacionInicial([valorFactura*0, aportacionInicial[1], aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([valorFactura*0, aportacionInicial[1], aportacionInicial[2]]));

            setEnganchePercent([0, enganchePercent[1], enganchePercent[2]])
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (0 / 100)), (valorFactura * (residualPercent[0] / 100)), 24, rate)
            setRenta([renta_calculada, renta[1], renta[2]])
            localStorage.setItem('renta_data', JSON.stringify([renta_calculada, renta[1], renta[2]]))

            document.getElementById('renta1').value = renta_calculada
          }else{
            // console.log("else");
            // console.log("APinicial");
            // console.log(result);
            document.getElementById('enganche1').value = result
            // console.log("acaaaaaaaaaaaaaaaaaaa");
            
            

            setAportacionInicial([result, aportacionInicial[1], aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([(valorFactura * (((result*100)/valorFactura).toFixed(2) / 100)), aportacionInicial[1], aportacionInicial[2]]));
            setEnganchePercent([((result*100)/valorFactura).toFixed(2), enganchePercent[1], enganchePercent[2]])
          }
        }}>Ajustar</button>
      </aside>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full truncate'>Residual</p>
        {/* <InputNumber placeholder='Residual' mode="currency" currency="USD" locale="en-US" value={ valorFactura * (residualPercent[0]/100) } */}
        <InputNumber placeholder='Residual' mode="currency" currency="USD" locale="en-US" value={ residualf5710.length !== 0 ? (valorFactura * (residualPercent[0]/100))+residualf5710[0]: 0}
                                                                                          
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} disabled/>
        <InputNumber inputId="percent" value={ residualPercent[0]+5 } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" 
        inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} disabled/>
       
      </aside>
    </div>
  )
}

function SecondTerm ( {valorFactura, residual2, setResidual2, renta, setRenta, aportacionInicial, setAportacionInicial, residualPercent, setResidualPercent, 
  enganchePercent, setEnganchePercent, showInfo, rate,residualf5710,setResidualf5710} ){
  const [showBtn, setShowBtn] = useState([false, false, false])

  // console.log("set resudial- 2")
  // console.log(residual2)
  // console.log("set resudial- f5710")
  // console.log(residualf5710)
  // // console.log("enganchePercent--")
  // // console.log(enganchePercent)
  // console.log("residualPercent---")
  // console.log(residualPercent)
  // console.log("aportacionInicial")
  // console.log(aportacionInicial)

  return (
    <div className='mb-4'>
      <p className='bg-gray-200 rounded rounded-md grid content-center py-2 mx-2'>36 Meses</p>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full'>Renta</p>
        <InputNumber inputId='renta2' placeholder='36 Meses con Aportacion' mode="currency" currency="USD" locale="en-US" value={renta[1]}
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-2" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}
        onChange={(e) => {
          setRenta([renta[0], e.value, renta[2]])
          localStorage.setItem('renta_data', JSON.stringify([renta[0], e.value, renta[2]]))

          setShowBtn([false, true, true])
        }} />
        <button className={showBtn[0] ? 'bg-sky-500 rounded-md text-white w-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:sky-500 focus:ring-offset-2' : 'hidden'} 
        onClick={(e) => {
          e.preventDefault()
          // console.log("residualPercent 1")
          // console.log(residualPercent);
          // console.log(residualPercent[1]-7);
          // console.log();

          setShowBtn([false, false, false])
          if( enganchePercent[1] > 35 ){    
           
            showInfo(`La aportacion inicial no puede ser mayor a 35%`)
            setEnganchePercent([enganchePercent[0], 35, enganchePercent[2]])
            
            setAportacionInicial([aportacionInicial[0], valorFactura*0.35, aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], valorFactura*0.35, aportacionInicial[2]]));

            let renta_calculada = fit_renta(valorFactura - (valorFactura * (35 / 100)), ((valorFactura * ((residualPercent[1]-7) / 100))), 36, rate)
            document.getElementById('renta2').value = renta_calculada

            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
          }
          else if( enganchePercent[1] < 0 ){
            showInfo(`La aportacion inicial no puede ser menor a 0%`)
            setEnganchePercent([enganchePercent[0], 0, enganchePercent[2]])
            
            setAportacionInicial([aportacionInicial[0], valorFactura*0, aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], valorFactura*0, aportacionInicial[2]]));

            let renta_calculada = fit_renta(valorFactura - (valorFactura * (0 / 100)), ((valorFactura * ((residualPercent[1]-7) / 100))), 36, rate)
            document.getElementById('renta2').value = renta_calculada
            
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
          }
          else if( residualPercent[1] > 40+7 ) {//47
           
            showInfo(`El valor Residual no puede ser mayor a 47%`)
          
            
            setResidualPercent([residualPercent[0], 40+7, residualPercent[2]])
            setResidual2([residual2[0], (valorFactura*0.4)+residualf5710[1], residual2[2]])
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (enganchePercent[1] / 100)), (valorFactura * (40 / 100)), 36, rate)
            document.getElementById('renta2').value = renta_calculada

            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
          }
          else if( residualPercent[1]-7 < 25-7 ) {   //18
            showInfo(`El valor Residual no puede ser menor a 25%`)
            // console.log(`procentajen ${residualPercent[1]} == 18888`);
            
            setResidualPercent([residualPercent[0], 25, residualPercent[2]])
            setResidual2([residual2[0], (valorFactura*0.25)-residualf5710[1], residual2[2]])
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (enganchePercent[1] / 100)), (valorFactura * (25 / 100))-residualf5710[1], 36, rate)
            document.getElementById('renta2').value = renta_calculada

            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
          }
          else {
            
            console.log(`Porcentaje ${residualPercent[1]}>=25`)
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (enganchePercent[1] / 100)), (valorFactura * ((residualPercent[1]-7) / 100)), 36, rate)
            // console.log(`renta calculada ${renta_calculada}`)
            document.getElementById('renta2').value = renta_calculada

            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
          }
        }}>Ajustar</button>
      </aside>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full truncate'>Aportación Inicial</p>
        <InputNumber inputId='enganche2' placeholder='Aportacion Inicial' mode="currency" currency="USD" locale="en-US" value={valorFactura * (enganchePercent[1] / 100)}
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}
        onChange={(e) => {
          setAportacionInicial([aportacionInicial[0], e.value, aportacionInicial[2]])
          localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], e.value, aportacionInicial[2]]));

          setEnganchePercent([enganchePercent[0], (e.value*100)/valorFactura, enganchePercent[2]])
          setShowBtn([true, false, true])
         }} />
        <InputNumber inputId="percent2" value={enganchePercent[1]} suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" 
        inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} onChange={ (e) => {
          setEnganchePercent([enganchePercent[0], e.value, enganchePercent[2]])
          
          setAportacionInicial([aportacionInicial[0], valorFactura * (e.value/100), aportacionInicial[2]])
          localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], valorFactura * (e.value/100), aportacionInicial[2]]));
         
          setShowBtn([true, false, true])
        } }/>
        <button className={showBtn[1] ? 'bg-sky-500 rounded-md text-white w-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:sky-500 focus:ring-offset-2' : 'hidden'}
        onClick={(e) => {
          e.preventDefault()     
          let result = fit_enganche(valorFactura, (valorFactura * ((residualPercent[1]-7)/100)), renta[1], 36, rate)
          // console.log("residualPercent[1]-7");
          // console.log(residualPercent[1]-7);
          
          // console.log("result");
          // console.log(result);


          setShowBtn([false, false, false])
          if(residualPercent[1] > 40+7){           
             
            showInfo(`El valor Residual no puede ser mayor a 47%`)
            setResidualPercent([residualPercent[0], 40+7, residualPercent[2]])
            setResidual2([residual2[0], (valorFactura*0.4)+residualf5710[1], residual2[2]])
            let enganche_calculado = fit_enganche(valorFactura, (valorFactura * (40/100))+residualf5710[1], renta[1], 36, rate)
            document.getElementById('enganche2').value = enganche_calculado
            setEnganchePercent([enganchePercent[0], (enganche_calculado*100 / valorFactura).toFixed(2), enganchePercent[2]])
            
            setAportacionInicial([aportacionInicial[0], enganche_calculado, aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], enganche_calculado, aportacionInicial[2]]));

          }else if(residualPercent[1]-7 < 25-7){//18
            // console.log("18");
            // console.log(residualPercent[1]);
            showInfo(`El valor Residual no puede ser menor a 25%`)
            setResidualPercent([residualPercent[0], 25, residualPercent[2]])
            setResidual2([residual2[0], (valorFactura*0.25)-residualf5710[1], residual2[2]])
            let enganche_calculado = fit_enganche(valorFactura, (valorFactura * (25/100))-residualf5710[1], renta[1], 36, rate)
            document.getElementById('enganche2').value = (enganche_calculado*100 / parseInt(valorFactura))
            setEnganchePercent([enganchePercent[0], (enganche_calculado*100 / valorFactura).toFixed(2), enganchePercent[2]])
            
            setAportacionInicial([aportacionInicial[0], enganche_calculado, aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], enganche_calculado, aportacionInicial[2]]));

          }else if((result*100)/valorFactura > 35){
            showInfo(`La aportacion inicial no puede ser mayor a 35%`)
            
            setAportacionInicial([aportacionInicial[0], valorFactura*0.35, aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], valorFactura*0.35, aportacionInicial[2]]));

            setEnganchePercent([enganchePercent[0], 35, enganchePercent[2]])
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (35 / 100)), (valorFactura * ((residualPercent[1]-7) / 100)), 36, rate)
            
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
            document.getElementById('renta2').value = renta_calculada

          }else if((result*100)/valorFactura < 0){
            showInfo(`La aportacion inicial no puede ser menor a 0`)
            setAportacionInicial([aportacionInicial[0], valorFactura*0, aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], valorFactura*0, aportacionInicial[2]]));

            setEnganchePercent([enganchePercent[0], 0, enganchePercent[2]])
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (0 / 100)), (valorFactura * ((residualPercent[1]-7) / 100)), 36, rate)
            
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
            document.getElementById('renta2').value = renta_calculada
          }else{
            
            // console.log(`cabio de la aportacion inicial: ${residualPercent[1]}`);
            // console.log((valorFactura * (((result*100)/valorFactura).toFixed(2) / 100)));

            document.getElementById('enganche2').value = result
            setAportacionInicial([aportacionInicial[0], result, aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], (valorFactura * (((result*100)/valorFactura).toFixed(2) / 100)), aportacionInicial[2]]));
            setEnganchePercent([enganchePercent[0], ((result*100)/valorFactura).toFixed(2), enganchePercent[2]])
          }
        }}>Ajustar</button>
      </aside>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full truncate'>Residual</p>
        <InputNumber inputId='residual2' placeholder='Residual' mode="currency" currency="USD" locale="en-US" value={ residualf5710.length !== 0 ? (valorFactura * (residualPercent[1]/100)): 0 } 
                                                                                                                      
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} 
        onChange={(e) => {
    
         
         
          
          localStorage.setItem('residual2_data', JSON.stringify([residual2[0], e.value, residual2[2]]));
          setResidual2([residual2[0], e.value, residual2[2]])

          setResidualPercent([residualPercent[0], (e.value*100)/valorFactura, residualPercent[2]])
          setShowBtn([true, true, false])
         }}/>
        <InputNumber inputId="percentResidual2" value={ residualPercent[1] } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" 
        inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} 
        onChange={ (e) => {
          setResidualPercent([residualPercent[0], e.value, residualPercent[2]])
          
          setResidual2([residual2[0], valorFactura * (e.value/100), residual2[2]])
          localStorage.setItem('residual2_data', JSON.stringify([residual2[0], valorFactura * (e.value/100), residual2[2]]));

          setShowBtn([true, true, false])
        } }/>
        <button className={showBtn[2] ? 'bg-sky-500 rounded-md text-white w-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:sky-500 focus:ring-offset-2' : 'hidden'}
        onClick={(e) => {
          e.preventDefault()
          // console.log(`jeje ${valorFactura}`);
          // console.log(`jeje ${ renta[1] }`);
          // console.log(`jeje ${ valorFactura * (enganchePercent[1]/100)}`);
          // console.log(`jeje ${ (enganchePercent[1]/100)}`);
          // console.log(`jeje ${ rate}`);
          // console.log(`jeje ${ ((valorFactura * 0.4)+residualf5710[1])}`);
          // console.log("enganchePercent")
          // console.log(enganchePercent)
          
          
          let result = fit_residual(valorFactura, renta[1], valorFactura * (enganchePercent[1]/100), 36, rate)
          // console.log("result")
          // console.log(result)
          // console.log(((valorFactura * 0.4)+residualf5710[1]))
        
          setShowBtn([false, false, false])
          if(enganchePercent[1] > 35){
           
            
            showInfo(`La aportacion inicial no puede ser mayor a 35%`)
            setEnganchePercent([enganchePercent[0], 35, enganchePercent[2]])
            
            setAportacionInicial([aportacionInicial[0], valorFactura*0.35, aportacionInicial[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], valorFactura*0.35, aportacionInicial[2]]));

            let residual_calculado = fit_residual(valorFactura, renta[1], (valorFactura * (35/100))+residualf5710[1], 36, rate)
            document.getElementById('residual2').value = residual_calculado
            
            setResidual2([residual2[0], residual_calculado, residual2[2]])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual_calculado, residual2[2]]));

            setResidualPercent([residualPercent[0], ((residual_calculado*100)/valorFactura).toFixed(2), residualPercent[2]])
          }else if(enganchePercent[1] < 0){
            showInfo(`La aportacion inicial no puede ser menor a 0%`)
            setEnganchePercent([enganchePercent[0], 0, enganchePercent[2]])
            
            setAportacionInicial([aportacionInicial[0], valorFactura*0, aportacionInicial[1]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], valorFactura*0, aportacionInicial[1]]));
            
            let residual_calculado = fit_residual(valorFactura, renta[1], valorFactura * (0), 36, rate)
            document.getElementById('residual2').value = residual_calculado
            
            setResidual2([residual2[0], residual_calculado, residual2[2]])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual_calculado, residual2[2]]));


            setResidualPercent([residualPercent[0], ((residual_calculado*100)/valorFactura).toFixed(2), residualPercent[2]])
          }else if(result > ((valorFactura * 0.4)+residualf5710[1])){
            
            
            showInfo(`El Valor Residual no puede ser mayor a 47%`)
            setResidual2([residual2[0], (valorFactura * 0.4)+residualf5710[1], residual2[2]])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], (valorFactura * 0.4)+residualf5710[1], residual2[2]]));



            setResidualPercent([residualPercent[0], 40+7, residualPercent[2]])
            let renta_calculada = fit_renta(valorFactura - aportacionInicial[1], (valorFactura * 0.4), 36, rate)

            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
            
            document.getElementById('renta2').value = renta_calculada
          }else if(result <= ((valorFactura * 0.25)-residualf5710[1])){
          
            
            showInfo(`El Valor Residual no puede ser menor a 25%`)
            
            setResidual2([residual2[0], (valorFactura * 0.25)-residualf5710[1], residual2[2]])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], (valorFactura * 0.25)-residualf5710[1], residual2[2]]));



            setResidualPercent([residualPercent[0], 25, residualPercent[2]])
            let renta_calculada = fit_renta(valorFactura - aportacionInicial[1], (valorFactura * 0.25) - residualf5710[1], 36, rate)
            
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta_calculada, renta[2]]))
            setRenta([renta[0], renta_calculada, renta[2]])
            document.getElementById('renta2').value = renta_calculada
          }else{
         
            result=result+residualf5710[1]
            
            document.getElementById('residual2').value = result.toFixed(2)
            
            setResidual2([residual2[0], result.toFixed(2), residual2[2]])

            // console.log("residual else iultimo ultimo");
            // console.log([residualPercent[0], ((result*100)/valorFactura).toFixed(2), residualPercent[2]]);
            // console.log("residual2");
            // console.log( (valorFactura* (((result*100)/valorFactura).toFixed(2))/100));
            // console.log( [residual2[0], valorFactura * ((result*100)/valorFactura).toFixed(2), residual2[2]]);
            // console.log("resudyak32");
            // console.log( [residual2[0], (valorFactura* (((result*100)/valorFactura).toFixed(2))/100)+residualf5710[1], residual2[2]]);



            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], (valorFactura* (((result*100)/valorFactura).toFixed(2))/100), residual2[2]]));

            setResidualPercent([residualPercent[0], ((result*100)/valorFactura).toFixed(2), residualPercent[2]])
          }
        }}>Ajustar</button>
      </aside>
    </div>
  )
}

function ThirdTerm ( {valorFactura, residual2, setResidual2, renta, setRenta, aportacionInicial, setAportacionInicial, residualPercent, setResidualPercent, 
  enganchePercent, setEnganchePercent, showInfo, rate,
  residualf5710,setResidualf5710
} ){
  const [showBtn, setShowBtn] = useState([false, false, false])
  
  return (
    <div className='mb-4'>
      <p className='bg-gray-200 rounded rounded-md grid content-center py-2 mx-2'>48 Meses</p>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full'>Renta</p>
        <InputNumber inputId='renta3' placeholder='48 Meses con Aportacion' mode="currency" currency="USD" locale="en-US" value={renta[2]}
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500 col-span-2" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}
        onChange={(e) => {

          setRenta([renta[0], renta[1], e.value])
          localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], e.value]))

          setShowBtn([false, true, true])
        }} />
        <button className={showBtn[0] ? 'bg-sky-500 rounded-md text-white w-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:sky-500 focus:ring-offset-2' : 'hidden'} 
        onClick={(e) => {
          e.preventDefault()
          
          setShowBtn([false, false, false])

          // console.log("REsiduak oircebr");
          
          // console.log(residualPercent);
          
          // console.log(enganchePercent[2])
          if( enganchePercent[2] > 35 ){
            console.log(">35");
            showInfo(`La aportacion inicial no puede ser mayor a 35%`)
            setEnganchePercent([enganchePercent[0], enganchePercent[1], 35])
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1], valorFactura*0.35])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], valorFactura*0.35]));


            let renta_calculada = fit_renta(valorFactura - (valorFactura * (35 / 100)), (valorFactura * ((residualPercent[2]-10) / 100)), 48, rate)
            document.getElementById('renta3').value = renta_calculada
            
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))
            setRenta([renta[0], renta[1], renta_calculada])
          }
          else if( enganchePercent[2] < 0 ){
            console.log("<0");
            showInfo(`La aportacion inicial no puede ser menor a 0%`)
            setEnganchePercent([enganchePercent[0], enganchePercent[1], 0])
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1], valorFactura*0])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], valorFactura*0]));

            let renta_calculada = fit_renta(valorFactura - (valorFactura * (0 / 100)), (valorFactura * ((residualPercent[2]-10) / 100)), 48, rate)
            document.getElementById('renta3').value = renta_calculada
            
            setRenta([renta[0], renta[1], renta_calculada])
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))
          }
          else if( residualPercent[2]-10 > 30 ) {//40
            // console.log("acaaaaa >30 interno");
            
            // console.log(residualPercent[2]-10);
            
            showInfo(`El valor Residual no puede ser mayor a 40%`)
            setResidualPercent([residualPercent[0], residualPercent[1], 30+10])
            
            setResidual2([residual2[0], residual2[1], (valorFactura*0.3)+residualf5710[2]])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], (valorFactura*0.3)+residualf5710[2]]));




            let renta_calculada = fit_renta(valorFactura - (valorFactura * (enganchePercent[2] / 100)), (valorFactura * (30 / 100)), 48, rate)
            document.getElementById('renta3').value = renta_calculada
            
            setRenta([renta[0], renta[1], renta_calculada])
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))
          }
          else if( residualPercent[2] < 1 ) {
           
            
            showInfo(`El valor Residual no puede ser menor a 1%`)
            setResidualPercent([residualPercent[0], residualPercent[1], 1])
            // console.log("acaaaaaaaaaaaaaaaaaaaaaaaaa")
            // console.log([residual2[0], residual2[1], (valorFactura*0.01)])
            setResidual2([residual2[0], residual2[1], (valorFactura*0.01)]) 
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], (valorFactura*0.01)]));
            
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (enganchePercent[2] / 100)), (valorFactura * (1 / 100))-residualf5710[2], 48, rate)
            document.getElementById('renta3').value = renta_calculada
            
            setRenta([renta[0], renta[1], renta_calculada])
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))
          }
          else {
            // console.log(`residual ${residualPercent[2]-10}`)
            
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (enganchePercent[2] / 100)), (valorFactura * ((residualPercent[2]-10) / 100)), 48, rate)
            document.getElementById('renta3').value = renta_calculada
            
            setRenta([renta[0], renta[1], renta_calculada])
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))
          }
        }}>Ajustar</button>
      </aside>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full truncate'>Aportación Inicial</p>
        <InputNumber inputId='enganche3' placeholder='Aportacion Inicial' mode="currency" currency="USD" locale="en-US" value={valorFactura * (enganchePercent[2] / 100)}
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}
        onChange={(e) => {
          setAportacionInicial([aportacionInicial[0], aportacionInicial[1], e.value])
          localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], e.value]));


          setEnganchePercent([enganchePercent[0], enganchePercent[1], (e.value*100)/valorFactura])
          setShowBtn([true, false, true])
         }} />
        <InputNumber inputId="percent3" value={enganchePercent[2]} suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" 
        inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} min={1} onChange={ (e) => {
          setEnganchePercent([enganchePercent[0], enganchePercent[1], e.value])
          
          setAportacionInicial([aportacionInicial[0], aportacionInicial[1], valorFactura * (e.value/100)])
          localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], valorFactura * (e.value/100)]));

          setShowBtn([true, false, true])
        } }/>

        <button className={showBtn[1] ? 'bg-sky-500 rounded-md text-white w-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:sky-500 focus:ring-offset-2' : 'hidden'}
        onClick={(e) => {
          e.preventDefault()
          // console.log("residual[2]");
          
          // console.log( fit_enganche(valorFactura, valorFactura * ((residualPercent[2]-10)/100), renta[2], 48, rate))
          let result = fit_enganche(valorFactura, valorFactura * ((residualPercent[2]-10)/100), renta[2], 48, rate)
          // console.log( result)
         
          setShowBtn([false, false, false])
          if(residualPercent[2]> 30+10){//40
            
            showInfo(`El valor Residual no puede ser mayor a 40%`)
            setResidualPercent([residualPercent[0], residualPercent[1], 30+10])
            
            setResidual2([residual2[0], residual2[1], (valorFactura*0.3)+residualf5710[2]])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], (valorFactura*0.3)+residualf5710[2]]));

            let enganche_calculado = fit_enganche(valorFactura, (valorFactura * (30/100))+residualf5710[2], renta[2], 48, rate)
            document.getElementById('enganche3').value = enganche_calculado
            setEnganchePercent([enganchePercent[0], enganchePercent[1], (enganche_calculado*100 / valorFactura).toFixed(2)])
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1], enganche_calculado])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], enganche_calculado]));

          }else if(residualPercent[2] < 1){
            
            showInfo(`El valor Residual no puede ser menor a 1%`)
            setResidualPercent([residualPercent[0], residualPercent[1], 1])
            
            setResidual2([residual2[0], residual2[1], (valorFactura*0.01)-residualf5710[2]])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], (valorFactura*0.01)-residualf5710[2]]));

            let enganche_calculado = fit_enganche(valorFactura, (valorFactura * (1/100))-residualf5710[2], renta[2], 48, rate)
            document.getElementById('enganche3').value = (enganche_calculado*100 / parseInt(valorFactura))
            setEnganchePercent([enganchePercent[0], enganchePercent[1], (enganche_calculado*100 / valorFactura).toFixed(2)])
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1], enganche_calculado])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], enganche_calculado]));
          }if((result*100)/valorFactura > 35){
            showInfo(`La aportacion inicial no puede ser mayor a 35%`)
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1], valorFactura*0.35])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], valorFactura*0.35]));


            setEnganchePercent([enganchePercent[0], enganchePercent[1], 35])
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (35 / 100)), (valorFactura * ((residualPercent[2]-10) / 100)), 48, rate)
            
            setRenta([renta[0], renta[1], renta_calculada])
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))

            document.getElementById('renta3').value = renta_calculada
          }else if((result*100)/valorFactura < 0){
            showInfo(`La aportacion inicial no puede ser menor a 0%`)
            // console.log("aportracion inicial");
            // console.log([aportacionInicial[0], aportacionInicial[1],( valorFactura*0)+residualf5710[2]]);
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1],( valorFactura*0)+residualf5710[2]])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1],( valorFactura*0)+residualf5710[2]]));
            // console.log("aportracion inicial");
            // console.log([aportacionInicial[0], aportacionInicial[1],( valorFactura*0)+residualf5710[2]]);


            setEnganchePercent([enganchePercent[0], enganchePercent[1], 0])
            let renta_calculada = fit_renta(valorFactura - (valorFactura * (0 / 100)), (valorFactura * ((residualPercent[2]-10) / 100)), 48, rate)
            
            setRenta([renta[0], renta[1], renta_calculada])
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))

            document.getElementById('renta3').value = renta_calculada
          }else{

            // console.log("Result de la aportacion para el 40% del residualk")
            // console.log(result+residualf5710[2])
            // console.log(residualf5710[2])
            
            document.getElementById('enganche3').value = result
            
            // console.log([aportacionInicial[0], aportacionInicial[1], result]);
            // console.log((valorFactura * (((result*100)/valorFactura).toFixed(2) / 100)));
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1], result])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], (valorFactura * (((result*100)/valorFactura).toFixed(2) / 100))]));
            // // localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], (valorFactura * (((result*100)/valorFactura).toFixed(2) / 100)), aportacionInicial[2]]));

            setEnganchePercent([enganchePercent[0], enganchePercent[1], ((result*100)/valorFactura).toFixed(2)])
          }
        }}>Ajustar</button>
      </aside>
      <aside className='p-fluid grid grid-cols-4 gap-2 m-2'>
        <p className='bg-gray-200 rounded rounded-md grid content-center py-2 w-full truncate'>Residual</p>
        <InputNumber inputId='residual3' placeholder='Residual' mode="currency" currency="USD" locale="en-US" value={ residualf5710.length !== 0 ? (valorFactura * (residualPercent[2]/100)): 0 }
        className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}} 
        onChange={(e) => {
          setResidual2([residual2[0], residual2[1], e.value])
          localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], e.value]));

          setResidualPercent([residualPercent[0], residualPercent[1], (e.value*100)/valorFactura])
          setShowBtn([true, true, false])
         }}/>
        <InputNumber inputId="percentResidual3" value={ residualPercent[2] } suffix="%" className="w-full rounded-none rounded-t-md text-gray-900 placeholder-gray-500" 
        inputStyle={{fontSize: '0.85rem', padding: '.5rem 1rem'}}  min={1} max={40}
        onChange={ (e) => {
          setResidualPercent([residualPercent[0], residualPercent[1], e.value])
          
          setResidual2([residual2[0], residual2[1], valorFactura * (e.value/100)])
          localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], valorFactura * (e.value/100)]));

          setShowBtn([true, true, false])
        } }/>
        <button className={showBtn[2] ? 'bg-sky-500 rounded-md text-white w-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:sky-500 focus:ring-offset-2' : 'hidden'} 
        onClick={(e) => {
          e.preventDefault()
          
          let result = fit_residual(valorFactura, renta[2], valorFactura * (enganchePercent[2]/100), 48, rate)
          // console.log("asasa");
          // console.log(result);
          // console.log(((valorFactura * 0.3)+residualf5710[2]));
          // console.log((valorFactura * 0.01)-residualf5710[2]);
          setShowBtn([false, false, false])
          if(enganchePercent[2] > 35){
            showInfo(`La aportacion inicial no puede ser mayor a 35%`)
            setEnganchePercent([enganchePercent[0], enganchePercent[1], 35])
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1], valorFactura*0.35])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], valorFactura*0.35]));

            let residual_calculado = fit_residual(valorFactura, renta[2], (valorFactura * (35/100))+residualf5710[2], 48, rate)
            document.getElementById('residual3').value = residual_calculado
            
            setResidual2([residual2[0], residual2[1], residual_calculado])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], residual_calculado]));

            setResidualPercent([residualPercent[0], residualPercent[1], ((residual_calculado*100)/valorFactura).toFixed(2)])
          }else if(enganchePercent[2] < 0){
            showInfo(`La aportacion inicial no puede ser menor a 0%`)
            setEnganchePercent([enganchePercent[0], enganchePercent[1], 0])
            
            setAportacionInicial([aportacionInicial[0], aportacionInicial[1], valorFactura*0])
            localStorage.setItem('apotacionInicial_data', JSON.stringify([aportacionInicial[0], aportacionInicial[1], valorFactura*0]));

            console.log("Aca fit residual");
            
            console.log(valorFactura, renta[2], (valorFactura * (0))+residualf5710[2], 48, rate)
            let residual_calculado = fit_residual(valorFactura, renta[2], (valorFactura * (0))+residualf5710[2], 48, rate)
            document.getElementById('residual3').value = residual_calculado
            
            setResidual2([residual2[0], residual2[1], residual_calculado])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], residual_calculado]));

            setResidualPercent([residualPercent[0], residualPercent[1], ((residual_calculado*100)/valorFactura).toFixed(2)])
          }else if(result > ((valorFactura * 0.3)+residualf5710[2])){
            showInfo(`El Valor Residual no puede ser mayor a 40%`)
            
            setResidual2([residual2[0], residual2[1], ((valorFactura * 0.3)+residualf5710[2])])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], (valorFactura * 0.3)+residualf5710[2]]));

            setResidualPercent([residualPercent[0], residualPercent[1], 30+10])
            let renta_calculada = fit_renta(valorFactura - aportacionInicial[2], (valorFactura * 0.3), 48, rate)
            
            setRenta([renta[0], renta[1], renta_calculada])
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))

            document.getElementById('renta3').value = renta_calculada
          }else if(result < ((valorFactura * 0.01)-residualf5710[2])){
            showInfo(`El Valor Residual no puede ser menor a 1%`)
            
            setResidual2([residual2[0], residual2[1], (valorFactura * 0.01)-residualf5710[2]])
            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], (valorFactura * 0.01)-residualf5710[2]]));

            setResidualPercent([residualPercent[0], residualPercent[1], 1])
            let renta_calculada = fit_renta(valorFactura - aportacionInicial[2], (valorFactura * 0.01)-residualf5710[2], 48, rate)
            
            setRenta([renta[0], renta[1], renta_calculada])
            localStorage.setItem('renta_data', JSON.stringify([renta[0], renta[1], renta_calculada]))

            document.getElementById('renta3').value = renta_calculada
          }else{
            // console.log("residual3333333333333333333");
            
            // console.log([residual2[0], residual2[1], (valorFactura* ((((result+residualf5710[2])*100)/valorFactura).toFixed(2))/100)])
            
            
            result=result+residualf5710[2]

            document.getElementById('residual3').value = result
            
            setResidual2([residual2[0], residual2[1], result])


            localStorage.setItem('residual2_data', JSON.stringify([residual2[0], residual2[1], (valorFactura* (((result*100)/valorFactura).toFixed(2))/100)]));

            setResidualPercent([residualPercent[0], residualPercent[1], ((result*100)/valorFactura).toFixed(2)])
          }
        }}>Ajustar</button>
      </aside>
    </div>
  )
}

export default function Arrendamiento () {
  const { valorFactura, residual2, setResidual2,residualf5710,setResidualf5710, renta, setRenta, aportacionInicial, setAportacionInicial, parametros } = useCotizacion()
  const [residualPercent, setResidualPercent] = useState([50, 47, 40])
  const [enganchePercent, setEnganchePercent] = useState([20, 20, 20])
  const toast = useRef(null)

  useEffect( () => {
    localStorage.setItem('apotacionInicial_data', JSON.stringify([valorFactura * (enganchePercent[0]/100), valorFactura * (enganchePercent[1]/100), valorFactura * (enganchePercent[2]/100)]));
    setAportacionInicial([valorFactura * (enganchePercent[0]/100), valorFactura * (enganchePercent[1]/100), valorFactura * (enganchePercent[2]/100)])
    
    setEnganchePercent([20, 20, 20])
    // console.log("residual")
    // console.log(residualPercent)
    // console.log(JSON.stringify([valorFactura * (residualPercent[0]/100), valorFactura * (residualPercent[1]/100), valorFactura * (residualPercent[2]/100)]))
    localStorage.setItem('residual2_data', JSON.stringify([valorFactura * (residualPercent[0]/100), valorFactura * (residualPercent[1]/100), valorFactura * (residualPercent[2]/100)]));
    setResidual2([valorFactura * (residualPercent[0]/100), valorFactura * (residualPercent[1]/100), valorFactura * (residualPercent[2]/100)])
    
    //residual fijo
      // console.log("residual");
      // console.log(residualPercent)
      // console.log([valorFactura * (residualPercent[0]/100), valorFactura * (residualPercent[1]/100), valorFactura * (residualPercent[2]/100)]);
      
      

    setResidualPercent([50, 47, 40])

    localStorage.setItem('renta_data', JSON.stringify([fit_renta( valorFactura -  valorFactura * (enganchePercent[0]/100), valorFactura * (residualPercent[0]/100), 24, parametros.rate), 
    fit_renta( valorFactura -  valorFactura * (enganchePercent[1]/100), (valorFactura * ((residualPercent[1]-7)/100)), 36, parametros.rate), 
    fit_renta( valorFactura -  valorFactura * (enganchePercent[2]/100), valorFactura * ((residualPercent[2]-10)/100), 48, parametros.rate)]));

    setRenta([fit_renta( valorFactura -  valorFactura * (enganchePercent[0]/100), valorFactura * (residualPercent[0]/100), 24, parametros.rate), 
    fit_renta( valorFactura -  valorFactura * (enganchePercent[1]/100), (valorFactura * ((residualPercent[1]-7)/100)), 36, parametros.rate), 
    fit_renta( valorFactura -  valorFactura * (enganchePercent[2]/100), valorFactura * ((residualPercent[2]-10)/100), 48, parametros.rate)])

    // console.log("Fit_renta");
    // console.log(renta)
    // console.log([fit_renta( valorFactura -  valorFactura * (enganchePercent[0]/100), (valorFactura * (residualPercent[0]/100)), 24, parametros.rate), 
    // fit_renta( valorFactura -  valorFactura * (enganchePercent[1]/100), (valorFactura * ((residualPercent[1]-7)/100)), 36, parametros.rate), 
    // fit_renta( valorFactura -  valorFactura * (enganchePercent[2]/100), (valorFactura * ((residualPercent[2]-10)/100)), 48, parametros.rate)])


    // eslint-disable-next-line
  }, [valorFactura])

 

  const showInfo = (message) => {
    toast.current.show({severity:'info', summary: 'Info: Se Realizara un Ajuste Automatico', detail: message, life: 3000})
  }

  return (
    <>
      <Toast ref={toast} />
      <section className='mb-10'>
        <h4 className='my-5 font-semibold text-lg'>Arrendamiento Con Aportación {`(Renta mensual sin I.V.A)`}</h4>
        
        <FirstTerm renta={renta} setRenta={setRenta} valorFactura={valorFactura} residualPercent={residualPercent} enganchePercent={enganchePercent} 
        aportacionInicial={aportacionInicial} setAportacionInicial={setAportacionInicial} setEnganchePercent={setEnganchePercent} showInfo={showInfo} rate={parametros.rate} setResidualPercent={setResidualPercent}
        setResidual2={setResidual2} residual2={residual2} 
        setResidualf5710={setResidualf5710} residualf5710={residualf5710} 
        />
        <SecondTerm valorFactura={valorFactura} residual2={residual2} setResidual2={setResidual2} renta={renta} setRenta={setRenta} aportacionInicial={aportacionInicial}
        setAportacionInicial={setAportacionInicial} residualPercent={residualPercent} setResidualPercent={setResidualPercent} enganchePercent={enganchePercent} 
        setEnganchePercent={setEnganchePercent} showInfo={showInfo} rate={parametros.rate}
        setResidualf5710={setResidualf5710} residualf5710={residualf5710} 
        
        />
        <ThirdTerm valorFactura={valorFactura} residual2={residual2} setResidual2={setResidual2} renta={renta} setRenta={setRenta} aportacionInicial={aportacionInicial}
        setAportacionInicial={setAportacionInicial} residualPercent={residualPercent} setResidualPercent={setResidualPercent} enganchePercent={enganchePercent} 
        setEnganchePercent={setEnganchePercent} showInfo={showInfo} rate={parametros.rate}
        setResidualf5710={setResidualf5710} residualf5710={residualf5710} 
        />
      </section>
    </>
  )
}