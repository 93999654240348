import { useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import { FileUpload } from 'primereact/fileupload'
import { ProgressBar } from 'primereact/progressbar'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Tag } from 'primereact/tag'
import { urlApi } from '../../helpers/helper'

function AutosDialog() {
  return (
    <ol className='ml-4'>
      <li><span className='mr-2 font-semibold'>1.</span>Dar click en <span className='text-blue-600'>Descargar Layout Autos {'(.csv)'}</span>.</li>
      <li><span className='mr-2 font-semibold'>2.</span>Guarda el archivo de hoja de cálculo{' (excel, numbers, etc.) '}en tu equipo con la extensión predeterminada.</li>
      <li><span className='mr-2 font-semibold'>3.</span>Captura el año {'(aaaa)'}, marca y modelo de los vehículos que vaya a cargar.</li>
      <li><span className='mr-2 font-semibold'>4.</span>Las celdas de los precios de los vehículos siempre deberán estar en formato "General", no deberán tener un
        formato de moneda, contabilidad o cualquier otro que genere separador de miles o simbolos de moneda.</li>
      <li><span className='mr-2 font-semibold'>5.</span>Captura la vigencia del precio del vehículo señalando una fecha de inicio y una de término del precio.
        Las celdas donde se inserten fechas, deberán ser en formato de “Fecha corta”, es decir, día, mes y año {'(dd/mm/aaaa)'}</li>
      <li><span className='mr-2 font-semibold'>6.</span>Indica si el vehículo es híbrido o no, insertando la palabra “SI” o “NO”.</li>
      <li><span className='mr-2 font-semibold'>7.</span>Concluida la captura del layout, vaya a la opción de “guardar como” y asegúrese que el “Tipo”
        de archivo sea en formato <strong className='underline'>CSV UTF-8 Delimitado por comas.</strong></li>
      <li><span className='mr-2 font-semibold'>8.</span>Da click en <span className='text-blue-600'>Archivo</span> y busca en tu equipo el archivo guardado, o si lo prefieres, arrastra el archivo hasta la sección marcada.</li>
      <li><span className='mr-2 font-semibold'>9.</span>Da click en <span className='text-blue-600'>Subir</span> y si el archivo fue cargado correctamente se desplegará un mensaje de éxito, de lo contrario te mostrará un mensaje de error,
        por lo que tendrás que reiniciar el procedimiento.</li>
      <h3 className='mt-2 font-semibold text-gray-500 underline'>Nota</h3>
      <li><span className='mr-2 font-semibold'>1.</span>El documento layout no debe contener <strong>comas {'(,)'}</strong> en ningún de sus campos de captura, lo anterior para que permita su carga.</li>
    </ol>
  )
}

function InsuranceDialog() {
  return (
    <ol className='ml-4'>
      <li><span className='mr-2 font-semibold'>1.</span>Dar click en <span className='text-blue-600'>Descargar Layout Seguros {'(.csv)'}</span>.</li>
      <li><span className='mr-2 font-semibold'>2.</span>Guarda el archivo de hoja de cálculo{' (excel, numbers, etc.) '}en tu equipo con la extensión predeterminada.</li>
      <li><span className='mr-2 font-semibold'>3.</span>Captura el año {'(aaaa)'}, marca y modelo de los vehículos que vaya a cargar, tal y como se capturaron en
        el <span className='font-semibold text-blue-600'>Layout Autos</span>.</li>
      <li><span className='mr-2 font-semibold'>4.</span>Las celdas de "Precio" del seguro siempre deberán estar en formato "General", no deberán tener un
        formato de moneda, contabilidad o cualquier otro que genere separador de miles o simbolos de moneda.</li>
      <li><span className='mr-2 font-semibold'>5.</span>Captura la vigencia del precio del vehículo señalando una fecha de inicio y una de término del precio.
        Las celdas donde se inserten fechas, deberán ser en formato de “Fecha corta”, es decir, día, mes y año {'(dd/mm/aaaa)'}</li>
      <li><span className='mr-2 font-semibold'>6.</span>Captura el nombre de la aseguradora {'(ejemplo: HDI, Qualitas, AXXA, etc.)'}</li>
      <li><span className='mr-2 font-semibold'>7.</span>Concluida la captura del layout, vaya a la opción de “guardar como” y asegúrese que el “Tipo”
        de archivo sea en formato <strong className='underline'>CSV UTF-8 Delimitado por comas.</strong></li>
      <li><span className='mr-2 font-semibold'>8.</span>Da click en <span className='text-blue-600'>Archivo</span> y busca en tu equipo el archivo guardado, o si lo prefieres, arrastra el archivo hasta la sección marcada.</li>
      <li><span className='mr-2 font-semibold'>9.</span>Da click en <span className='text-blue-600'>Subir</span> y si el archivo fue cargado correctamente se desplegará un mensaje de éxito, de lo contrario te mostrará un mensaje de error,
        por lo que tendrás que reiniciar el procedimiento.</li>
      <h3 className='mt-2 font-semibold text-gray-500 underline'>Nota</h3>
      <li><span className='mr-2 font-semibold'>1.</span>El documento layout no debe contener <strong>comas {'(,)'}</strong> en ningún de sus campos de captura, lo anterior para que permita su carga.</li>
    </ol>
  )
}

function AgencyDialog() {
  return (
    <ol className='ml-4'>
      <li><span className='mr-2 font-semibold'>1.</span>Dar click en <span className='text-blue-600'>Descargar Layout Agencias {'(.csv)'}</span>.</li>
      <li><span className='mr-2 font-semibold'>2.</span>Guarda el archivo de hoja de cálculo{' (excel, numbers, etc.) '}en tu equipo con la extensión predeterminada.</li>
      <li><span className='mr-2 font-semibold'>3.</span>Captura los datos solicitados en su celda correspondiente.</li>
      <li><span className='mr-2 font-semibold'>4.</span>Concluida la captura del layout, vaya a la opción de “guardar como” y asegúrese que el “Tipo”
        de archivo sea en formato <strong className='underline'>CSV UTF-8 Delimitado por comas.</strong></li>
      <li><span className='mr-2 font-semibold'>5.</span>Da click en <span className='text-blue-600'>Archivo</span> y busca en tu equipo el archivo guardado, o si lo prefieres, arrastra el archivo hasta la sección marcada.</li>
      <li><span className='mr-2 font-semibold'>6.</span>Da click en <span className='text-blue-600'>Subir</span> y si el archivo fue cargado correctamente se desplegará un mensaje de éxito, de lo contrario te mostrará un mensaje de error,
        por lo que tendrás que reiniciar el procedimiento.</li>
      <h3 className='mt-2 font-semibold text-gray-500 underline'>Nota</h3>
      <li><span className='mr-2 font-semibold'>1.</span>El documento layout no debe contener <strong>comas {'(,)'}</strong> en ningún de sus campos de captura, lo anterior para que permita su carga.</li>
    </ol>
  )
}

export default function UploadFile({ label }) {
  const [totalSize, setTotalSize] = useState(0)
  const toast = useRef(null)
  const fileUploadRef = useRef(null)
  const [displayResponsive, setDisplayResponsive] = useState(false)

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize
    // e.files.forEach(file => {
    //     _totalSize += file.size
    // })
    _totalSize += e.files[0].size
    setTotalSize(_totalSize)
  }

  const onTemplateUpload = (e) => {
    // let _totalSize = 0
    // e.files.forEach(file => {
    //     _totalSize += (file.size || 0)
    //     console.log(file)
    // })
    setTotalSize(e.files[0].size)
    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Archivo Cargado' })
    // console.log(e)
  }

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size)
    callback()
  }

  const onTemplateClear = () => {
    setTotalSize(0)
  }

  const onErrorClear = (e) => {
    console.log(e)
    setTotalSize(0)
    toast.current.show({ severity: 'error', summary: 'Error: Algo ha sucedido con el Archivo.', detail: 'Asegurese que el contenido del archivo, cumpla con el formato .csv requerido.' })
  }

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options
    const value = totalSize / 10000
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B'

    return (
      <div className={className} style={{ backgroundColor: 'var(--surface-b)', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{ width: '300px', height: '20px', marginLeft: 'auto' }}></ProgressBar>
      </div>
    )
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="flex content-center gap-4 lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 sm:flex justify-items-center">
        <div className='grid content-center block w-full'>
          <span className='mx-4'>
            {file.name}
            <span className='block px-5 sm:block lg:inline md:inline'>{new Date().toLocaleDateString()}</span>
          </span>
        </div>
        <div className='block w-full text-start'>
          <Tag value={props.formatSize} severity="warning" className="p-tag-rounded" style={{ padding: '0.8rem', margin: '0 0.5rem' }} />
          <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger" tooltip="Eliminar Archivo"
            onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className='text-center'>
        <i className="p-5 mt-3 pi pi-file" style={{ 'fontSize': '5em', color: 'var(--surface-d)' }}></i>
        <p style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">Arrastra el archivo en esta Sección</p>
      </div>
    )
  }

  const chooseOptions = { label: 'Archivo', icon: 'pi pi-fw pi-file', className: 'custom-choose-btn p-button-rounded p-button-outlined p-button-sm' }
  const uploadOptions = { label: 'Subir', icon: 'pi pi-fw pi-cloud-upload', className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined p-button-sm' }
  const cancelOptions = { label: 'Cancelar', icon: 'pi pi-fw pi-times', className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined p-button-sm' }

  return (
    <>
      <Toast ref={toast}></Toast>
      <Tooltip target=".custom-choose-btn" content="Seleccionar Archivo" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Cargar Archivo" position="bottom" tooltipoptions={{ showDelay: 1000, hideDelay: 300 }} />
      <Tooltip target=".custom-cancel-btn" content="Eliminar Archivo" position="bottom" tooltipoptions={{ showDelay: 1000, hideDelay: 300 }} />
      <div className='px-10 text-end'>
        <Button label='Ayuda' onClick={() => setDisplayResponsive(true)} className='p-button-warning p-button-rounded p-button-sm' />
      </div>
      <Dialog header="Subir Archivo" visible={displayResponsive} onHide={() => setDisplayResponsive(false)} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }}>
        {label === 'Autos' ? <AutosDialog /> : label === 'Seguros' ? <InsuranceDialog /> : <AgencyDialog />}
      </Dialog>

      <h1 className='mt-10 text-3xl font-semibold text-center text-gray-900'>Cargar Archivo {label}</h1>
      <section className='w-11/12 mx-auto lg:w-9/12 md:w-10/12 sm:w-11/12'>
        <div className='grid justify-center w-full my-10'>
          <h2 className='text-lg'>En este módulo se deberán cargar los archivos con extensión .csv, en caso de no tener el archivo descargar
            el siguiente layout:</h2>
          <a href={label === 'Seguros' ? '/layoutSeguros.csv' : label === 'Autos' ? '/layoutAutos.csv' : '/layoutAgencias.csv'}
            download={`layout${label}.csv`} className='py-2 mx-20 mt-5 text-center text-black rounded-md bg-amber-400 hover:bg-amber-600 hover:text-white'>{`Descargar Layout ${label} (.csv)`}</a>
        </div>
        <section className="px-4 mx-10 mb-10 sm:px-2 lg:px-2">
          <FileUpload ref={fileUploadRef} name="files[]" url={label === 'Seguros' ? `${urlApi}/post/insurance` : label === 'Autos' ? `${urlApi}/post/car`
            : `${urlApi}/post/agency`}
            maxFileSize={1000000} className='w-full' accept='.csv' onUpload={onTemplateUpload} onSelect={onTemplateSelect}
            onError={onErrorClear} onClear={onTemplateClear} headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
        </section>
      </section>
    </>
  )
}