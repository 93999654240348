import { useLogin } from "../../hooks/useLogin"
import { Link } from 'wouter'

export default function Header() {
  const { route } = useLogin()
  return (
    <>
      <header className="absolute sticky top-0 z-50 grid grid-cols-2 px-5 py-4 border-b-4 border-indigo-400 bg-amber-400"
      // style={{ backgroundColor: '#0A83C7' }}  ///v2
      >
        <Link to={route}><img src="/logomovex.png" alt="Movex Logo" className="h-[2.5rem] bg-white px-2 py-1 mx-5 rounded-lg" /></Link>
        {/* { route !== '/' && <Link to='/logout' className="py-2 mx-5 text-lg text-white justify-self-end" onClick={() => {
          setRoute('/')
          setUser({})
        }}><i className="mr-2 pi pi-sign-out"></i>Cerrar Sesión</Link> } */}
      </header>
    </>
  )
}