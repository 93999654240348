import AddPromo from './AddPromo'
import UploadPromo from './UploadPromo'

export default function Promociones () {

  return (
    <>
      <section className='md:my-20 sm:my-2 md:mx-20 sm:mx-2 my-2 mx-2 grid grid-cols-12 gap-2'>
        <aside className='lg:col-span-4 md:col-span-12 sm:col-span-12 col-span-12 mx-auto'><AddPromo /></aside>
        <aside className='lg:col-span-8 md:col-span-12 sm:col-span-12 col-span-12'>
          <UploadPromo />
        </aside>
      </section>
    </>
  )
}