import React, {useState} from 'react'

//Para Manejar el estado de forma global
const Context = React.createContext({})

export function LoginContextProvider ({children}) {
    const [user, setUser] = useState({})
    const [route, setRoute] = useState('/')
    const [component, setComponent] = useState('dashboard')
    return (
        <Context.Provider value={{user, setUser, route, setRoute, component, setComponent}} >
            {children}
        </Context.Provider>
    )
}

export default Context