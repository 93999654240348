import { useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import { FileUpload } from 'primereact/fileupload'
import { ProgressBar } from 'primereact/progressbar'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'
import { urlApi } from '../../helpers/helper'
import { InputText } from 'primereact/inputtext'

import Documents from '../DataTable/Documents'

export default function UploadDocument ( ) {
  const [totalSize, setTotalSize] = useState(0)
  const [ fileName, setFileName ] = useState('')
  const [ extension, setExtension ] = useState('')
  const [persona,setPersona] = useState('pm')
  const toast = useRef(null)
  const fileUploadRef = useRef(null)

  const [trigger, setTrigger] = useState(true)

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize
    _totalSize += e.files[0].size
    setTotalSize(_totalSize)
    let fileExtension = e.files[0].name.split('.')
    setExtension(`.${fileExtension[fileExtension.length-1]}`)
  }

  const onTemplateUpload = (e) => {
    setTotalSize(e.files[0].size)
    toast.current.show({severity: 'success', summary: 'Success', detail: 'Archivo Cargado'})
    setFileName('')
    setTrigger(true)
  }

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size)
    callback()
  }

  const onTemplateClear = () => {
    setTotalSize(0)
  }

  const onErrorClear = (e) => {
    //console.log(e)
    setTotalSize(0)
    toast.current.show({severity: 'error', summary: 'Error: Algo ha sucedido con el Archivo.', detail: 'Asegurese que el contenido del archivo, cumpla con el formato .csv requerido.'})
  }
  //para el obtener el tipo de documento para cierta persona
  function cambioPersona(e) {
    const perso = document.getElementById("person").value;
    setPersona(e.target.value)
  }

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options
    const value = totalSize/10000
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B'

    return (
      <div className={className} style={{backgroundColor: 'var(--surface-b)', display: 'flex', alignItems: 'center'}}>
        {chooseButton}
        {fileName !== '' && uploadButton}
        {cancelButton}
        <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{width: '8rem', height: '2rem', marginLeft: 'auto'}}
        color='#059669'></ProgressBar>
      </div>
    )
  }

  const itemTemplate = (file, props) => {
    return (
      <div className="lg:grid lg:grid-cols-2 md:grid md:grid-cols-2 sm:flex flex justify-items-center content-center gap-4">
        <div className='w-full grid content-center block'>
          <span  className='mx-4'>
              {file.name}
              <span className='px-5 sm:block block lg:inline md:inline'>{new Date().toLocaleDateString()}</span>
          </span>
        </div>
        <div className='w-full text-start block'>
          <Tag value={props.formatSize} severity="warning" className="p-tag-rounded" style={{padding: '0.8rem', margin: '0 0.5rem'}}/>
          <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger" tooltip="Eliminar Archivo"
          onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      </div>
    )
  }

  const emptyTemplate = () => {
    return (
      <div className='text-center'>
        <i className="pi pi-file mt-3 p-5" style={{'fontSize': '5em', color: 'var(--surface-d)'}}></i>
        <p style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">Arrastra el archivo en esta Sección</p>
      </div>
    )
  }

  const chooseOptions = {label: 'Archivo', icon: 'pi pi-fw pi-file', className: 'custom-choose-btn p-button-rounded p-button-outlined p-button-sm'}
  const uploadOptions = {label: 'Subir', icon: 'pi pi-fw pi-cloud-upload', className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined p-button-sm'}
  const cancelOptions = {label: 'Cancelar', icon: 'pi pi-fw pi-times', className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined p-button-sm'}

  return(
    <>
      <Toast ref={toast}></Toast>
      <Tooltip target=".custom-choose-btn" content="Seleccionar Archivo" position="bottom"/>
      <Tooltip target=".custom-upload-btn" content="Cargar Archivo" position="bottom" tooltipoptions={{showDelay: 1000, hideDelay: 300}}/>
      <Tooltip target=".custom-cancel-btn" content="Eliminar Archivo" position="bottom" tooltipoptions={{showDelay: 1000, hideDelay: 300}}/>

      <h1 className='mt-10 text-center text-3xl font-semibold text-gray-900'>Subir Documento</h1>
      <section className='mx-auto xl:w-10/12 lg:w-11/12 md:w-11/12 w-full'>
        <div className='grid justify-center my-10 w-full'>
          <h2 className='text-lg text-center'>En este módulo se deberán cargar los archivos de requisitos y solicitudes tanto de persona moral como de persona física, es necesario que a estos archivos se les asigne un "nombre" para su carga, y este mismo nombre será el que aparezca en pantalla. Los archivos se ordenan de manera alfabética.</h2>
          {/* <p className='taxt-gray-800 mt-2 underline underline-offset-2'>Nota: Es necesario escribir el nombre del documento para habilitar su carga.</p> */}
        </div>
        <section className="mx-10 px-4 sm:px-2 lg:px-2 mb-10 grid xl:grid-cols-2 grid-cols-1 gap-5 py-10">
          <aside>
            <span className="p-float-label p-fluid mb-5">
              <InputText id="filename" value={fileName} onChange={(e) => setFileName(e.target.value)} />
              <label htmlFor="filename">Nombre del Archivo</label>
            </span>

            <form >
            <span className="p-float-label p-fluid mb-5">
              <select id="person" value={persona}  onChange={cambioPersona} placeholder='Modelo' className="relative block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
      placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm">
                  <option value="pm" id="pm">Persona Moral</option>
                  <option value="pf" id="pf">Persona Física</option>
                </select>
            </span>

            </form>


            {/* <FileUpload ref={fileUploadRef} name="files[]" url={`${urlApi}/post/document/${fileName}/${extension}`}  */}
            <FileUpload ref={fileUploadRef} name="files[]" url={`${urlApi}/post/document/${persona}/${fileName}/${extension}`} 
              maxFileSize={1000000} className='w-full' accept='*' onUpload={onTemplateUpload} onSelect={onTemplateSelect}
              onError={onErrorClear} onClear={onTemplateClear} headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
              chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
          </aside>

          <aside><Documents trigger={trigger} setTrigger={setTrigger} /></aside>
        </section>
      </section>
    </>
  )
}