import { useLocation } from 'wouter'
import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { loginUrl, version_Movex } from '../../helpers/helper'
import Header from '../../components/Header'

/*Fromulario para Acceso a la aplicación*/
export default function Login({ setIsLogged, isLogged }) {
  const [, setLocation] = useLocation()
  const [alert, setAlert] = useState(false)
  const { setUser, setRoute, setComponent } = useLogin()
  const [message, setMessage] = useState('')

  const handlerSubmit = (e) => {
    e.preventDefault()
    const email = document.getElementById('email').value
    const password = document.getElementById('password').value

    //Validar el inicio de sesion
    if (Object.keys(isLogged).length > 0) {
      setUser(isLogged)
      if (isLogged.tipo === 'supervisor') {
        setLocation('/supervisor')
        setRoute('/supervisor')
      } else if (isLogged.tipo === 'vendedor') {
        setLocation('/vendedor')
        setRoute('/vendedor')
      } else if (isLogged.tipo === 'administrador') {
        setLocation('/admin')
        setRoute('/admin')
      } else {
        setLocation('/broker')
        setRoute('/broker')
      }
    }
    else {
      fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, contrasenia: password }),
      }).then((res) => res.json())
        .then((result) => {
          setUser(result.data)
          setMessage(result.message)
          if (result.data !== null) {
            setComponent('dashboard')
            setIsLogged(result.data)
            localStorage.setItem('user', JSON.stringify(result.data)) //Guardar datos de sesion
            if (result.data.tipo === 'supervisor') {
              setTimeout(() => {
                setLocation('/supervisor')
                setRoute('/supervisor')

              }, 300);
            } else if (result.data.tipo === 'vendedor') {
              setTimeout(() => {
                setLocation('/vendedor')
                setRoute('/vendedor')

              }, 300);
            } else if (result.data.tipo === 'administrador') {
              setTimeout(() => {
                setLocation('/admin')
                setRoute('/admin')

              }, 300);
            } else {
              setLocation('/broker')
              setRoute('/broker')
            }
          } else {
            setAlert(true)

          }
        })
    }
  }

  return (
    <>
      <Header />

      {/* Alert */}
      {alert &&
        <section className='relative px-4 py-3 mx-10 mt-5 text-red-700 bg-red-100 border border-red-400 rounded'>
          <strong className='fw-bold'>Error! </strong>
          <span className='block sm:inline'>{message}</span>
          <button className='absolute top-0 bottom-0 right-0 px-4 py-3' onClick={() => setAlert(false)}>
            <svg className="w-6 h-6 text-red-500 fill-current" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
          </button>
        </section>}

      <section className="flex items-center justify-center min-h-full px-4 py-20 mt-20 sm:px-6 lg:px-8">
        <div className="w-full max-w-md px-5 py-12 space-y-8 bg-white border border-gray-200 rounded-md shadow-md">
          <form className="px-5 py-4 text-center" onSubmit={handlerSubmit}>
            <h2 className="mt-6 mb-10 text-3xl font-bold tracking-tight text-center text-gray-900">¡Bienvenido!</h2>
            <input type="text" id="email" placeholder="Correo Electronico" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md focus:z-10 focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-200 hover:border-blue-400 sm:text-sm" autoFocus />
            <input type="password" id="password" placeholder="Contraseña" className="relative block w-full px-3 py-2 mt-5 mb-10 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md focus:z-10 focus:border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-200 hover:border-blue-400 sm:text-sm" />
            <button className="flex justify-center w-full px-4 py-2 text-sm font-bold text-black border border-transparent rounded-md bg-amber-500 hover:bg-amber-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2">
              Entrar
            </button>

          </form>
        </div>
      </section>

      <section>
        <img src='/bglogin2.svg' alt='' className='absolute bottom-0 left-0 -z-50' />
        {/* <img src='/page-turner.svg' alt='' className='absolute bottom-0 left-0 -z-50' /> */}
      </section>
      {/* <div className='fixed bottom-0 grid content-center w-full px-5 pb-2 bg-gray-900 text-end sm:bg-transparent'><p className='text-gray-50 text-[0.85rem] py-1'>Cotizador Movex v.1.3.2 by BMTools</p></div> */}
      <div className='fixed bottom-0 grid content-center w-full px-5 pb-2 bg-gray-900 text-end sm:bg-transparent'><p className='text-amber-950 text-[0.85rem] py-1'>{version_Movex}</p></div>
    </>
  )
}