import { useEffect, useState } from 'react'
import { urlServer } from '../../helpers/helper'

function DocumentList ( {documents} ) {
  return <div className='my-10 grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-cols-1  '>
    <div className='mr-4 p-4 border-2 rounded-lg border-gray-300'>
     
      <h2 className='my-4 font-semibold text-xl'><i className='pi pi-building mr-2'></i>Persona Moral</h2>
      {
        documents.length > 0 ? <div className='grid  grid-cols-1'>
       
        {documents.filter(el => (el.name.split('-')[1] === "pm")).map((el, index) =>

              // {let urlB = el.url
              // let newURLStorage=urlB.replace('public/index.php','storage/app')
              // el.url=newURLStorage
              // console.log(`ulr: ${el.url}`)}
              
              // <a key={index} href={el.url} className='block text-blue-500 text-lg hover:text-blue-800' target='_blank' rel='noreferrer' >
              
              //descomentar ciuando este en local la primera a
              <a key={index} href={el.url.replace('public/index.php','storage/app')} className='block text-blue-500 text-lg hover:text-blue-800' target='_blank' rel='noreferrer' >
                  <i className='pi pi-file mr-2'></i>{el.name.split('-')[0]}  
              </a> 
            ) 
        }
        </div>
        : <p className='text-red-600 text-lg font-semibold'>No hay documentos que mostrar.</p> 
      }
    </div>
    <div className='mr-4 p-4 border-2 rounded-lg border-gray-300'>
      <h2 className='my-4 font-semibold text-xl'><i className='pi pi-user mr-2'></i>Persona Física</h2>
      {
        documents.length > 0 ? <div className='grid  grid-cols-1'>
       
        {documents.filter(el => (el.name.split('-')[1] === "pf")).map((el, index) =>
              
              // <DocumentListAux></DocumentListAux>
              //local
                // <a key={index} href={el.url} className='block text-blue-500 text-lg hover:text-blue-800' target='_blank' rel='noreferrer' >
                //     <i className='pi pi-file mr-2'></i>{el.name.split('-')[0]}  
                // </a> 
              //servidor
              <a key={index} href={el.url.replace('public/index.php','storage/app')} className='block text-blue-500 text-lg hover:text-blue-800' target='_blank' rel='noreferrer' >
                  <i className='pi pi-file mr-2'></i>{el.name.split('-')[0]}  
              </a> 
            
            ) 
        }
        </div>
        : <p className='text-red-600 text-lg font-semibold'>No hay documentos que mostrar.</p> 
      }
    </div>    
  </div>
}
export default function ListDocuments () {
  const [ documents, setDocuments ] = useState([])
  useEffect(() => {
    fetch(`${urlServer}/api/get/document`)
      .then(res => res.json())
      .then(data => {

        if(data.length > 0){
          let document = [], file = []
          data.map((el, index) => {
            file = el.split('.')
            document.push({name: file[0], url: `${urlServer}/documents/${el}`, ext: file[1], index})
            return null
          })
         
          setDocuments(document)
        }
      })
  }, [])

  return(
    <>
      <DocumentList documents={documents} />
    </>
  )
}