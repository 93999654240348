import { useEffect, useState, useRef } from 'react'
import Cotizador from '../../components/Cotizador/'
//import Header from '../../components/Header'
import Asesor from '../../components/Dashboard/Asesor'
import { Link } from 'wouter'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useLogin } from '../../hooks/useLogin'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { putUserUrl, version_Movex } from '../../helpers/helper'
import { Toast } from 'primereact/toast'

/*Vendedor Page*/
export default function Vendedor({ isLogged }) {
  const profile = useRef()
  const toast = useRef(null)
  const { setUser, setRoute, route, user } = useLogin()
  const [component, setComponent] = useState('dashboard')

  useEffect(() => {
    setUser(isLogged)
    setRoute('/vendedor')
  }, [isLogged, setRoute, setUser])

  const showInfo = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 })
  }

  const handlerSubmit = (e) => {
    e.preventDefault()
    profile.current.toggle(false)
    let updateUser = {}
    let nombres = '', apellido_materno = '', apellido_paterno = '', contrasenia = ''
    nombres = (document.getElementById('nombres').value !== undefined && document.getElementById('nombres').value !== null && document.getElementById('nombres').value !== '')
      ? document.getElementById('nombres').value : user.nombres
    apellido_paterno = (document.getElementById('apellidoPaterno').value !== undefined && document.getElementById('apellidoPaterno').value !== null && document.getElementById('apellidoPaterno').value !== '')
      ? document.getElementById('apellidoPaterno').value : user.apellido_paterno
    apellido_materno = (document.getElementById('apellidoMaterno').value !== undefined && document.getElementById('apellidoMaterno').value !== null && document.getElementById('apellidoMaterno').value !== '')
      ? document.getElementById('apellidoMaterno').value : user.apellido_materno
    contrasenia = (document.getElementById('contrasenia').value !== undefined && document.getElementById('contrasenia').value !== null && document.getElementById('contrasenia').value !== '')
      ? document.getElementById('contrasenia').value : ''
    contrasenia === '' ? updateUser = { id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno } :
      updateUser = { id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno, contrasenia: contrasenia }
    console.log(updateUser)

    fetch(putUserUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUser),
    }).then((res) => res.json())
      .then((result) => {
        result.message === 'Success' && showInfo('Los datos fueron actualizados.')
      })
  }

  return (
    <>
      <Toast ref={toast} />
      <OverlayPanel ref={profile} showCloseIcon id='overlay_panel' style={{ backgroundColor: '#f3f4f6' }}>
        <form className='px-4 pb-4 p-fluid' onSubmit={handlerSubmit}>
          <h2 className='mb-4 text-lg font-semibold text-center'>Actualizar Datos del Perfil</h2>
          <InputText id='nombres' defaultValue={user.nombres} style={{ height: '2rem', margin: '0.5rem 0' }}
            className="block w-full my-4 text-left text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md sm:text-sm" />
          <InputText id='apellidoPaterno' defaultValue={user.apellido_paterno} style={{ height: '2rem', margin: '0.5rem 0' }}
            className="block w-full my-4 text-left text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md sm:text-sm" />
          <InputText id='apellidoMaterno' defaultValue={user.apellido_materno} style={{ height: '2rem', margin: '0.5rem 0' }}
            className="block w-full my-4 text-left text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md sm:text-sm" />
          <Password inputId='contrasenia' placeholder='Contraseña' toggleMask inputStyle={{ height: '2rem' }}
            className="block w-full text-left text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md sm:text-sm" />
          <button className='w-full py-2 my-5 text-white bg-indigo-500 rounded-lg hover:bg-indigo-600'>Actualizar</button>
        </form>
      </OverlayPanel>

      <header className="absolute sticky top-0 z-50 grid grid-cols-2 px-5 py-4 border-b-4 border-indigo-900 bg-amber-400">
        <Link to={route}><img src="/logomovex.png" alt="Movex Logo" className="h-[2.5rem] bg-white px-2 py-1 mx-5 rounded-lg" /></Link>
        <div className='flex mr-5 flex-cols justify-self-end'>
          <button className='px-5 py-2 text-lg text-black rounded-lg justify-self-end hover:bg-gray-700 hover:text-white hover:underline hover:underline-offset-4'
            onClick={() => setComponent('dashboard')}> <i className='mr-2 pi pi-home'></i>Inicio</button>
          <button className='px-5 py-2 text-lg text-black rounded-lg justify-self-end hover:bg-gray-700 hover:text-white hover:underline hover:underline-offset-4'
            onClick={() => setComponent('cotizador')}> <i className='mr-2 pi pi-money-bill'></i>Cotizador</button>

          <button className='px-5 py-2 text-lg text-black rounded-lg justify-self-end hover:bg-gray-700 hover:text-white hover:underline hover:underline-offset-4'
            onClick={(e) => profile.current.toggle(e)}> <i className='mr-2 pi pi-user-edit'></i>Editar Perfil</button>
          <Link to='/logout' className="px-5 py-2 mx-2 ml-5 text-lg text-black rounded-lg justify-self-end hover:bg-gray-700 hover:text-white" onClick={() => {
            setRoute('/')
            setUser({})
          }}><i className="mr-2 pi pi-sign-out"></i>Cerrar Sesión</Link>
        </div>
      </header>
      {/* <section className='flex flex-row'>
        <aside className='sticky top-0 h-screen w-64 bg-gray-900 rounded-r-[2.4rem] ring-8 ring-indigo-300'>
          <div className='my-20 ml-3'>
            
          </div>
        </aside>
      </section> */}

      <section className='mx-20'>
        {component === 'dashboard' ? <Asesor user={user} />
          : component === 'cotizador' && <div className='mx-20 mt-10'><Cotizador /></div>}
      </section>

      {/* <div className='fixed bottom-0 grid content-center w-full px-10 bg-gray-900'><p className='text-white text-[0.8rem] py-1'>Cotizador Movex v.1.3.2 by BMTools</p></div> */}
      <div className='fixed bottom-0 grid content-center w-full px-10 bg-amber-400'><p className='text-black text-[0.8rem] py-1'>{version_Movex}</p></div>
    </>
  )
}