import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { FilterMatchMode } from 'primereact/api'
import { useState } from 'react'
import { useEffect } from 'react'
import { urlApi } from '../../helpers/helper'
// import { InputText } from 'primereact/inputtext'

import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'                  //core css
import 'primeicons/primeicons.css'

export default function UsersTable ( {tipo, title} ) {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [brands, setBrands] = useState([])
  const [agencies, setAgencies] = useState([])
  const [filters] = useState({
    'name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'agencia': { value: null, matchMode: FilterMatchMode.EQUALS },
    'marca': { value: null, matchMode: FilterMatchMode.EQUALS }
  })
  
  useEffect( () => {
    setLoading(true)
    fetch(`${urlApi}/get/user/q=${tipo}`)
      .then(res => res.json())
      .then(result => {
        let marcas = []
        let agencias = []
        result.map(el => {
          el.name = `${el.nombres} ${el.apellido_paterno} ${el.apellido_materno}`
          marcas.push(el.marca)
          agencias.push(el.agencia)
          return null
        })
        setBrands([...new Set(marcas)])
        setAgencies([...new Set(agencias)])
        setUsers(result)
      })
    setLoading(false)
  }, [tipo])

  const header = (
    <div className='table-header text-center text-3xl font-semibold'>{title}</div>
  )

  // Flitros
  const brandRowFilterTemplate = (options) => {
    return <Dropdown value={options.value} options={brands} onChange={(e) => options.filterApplyCallback(e.value)}
    placeholder="Selecciona una marca" className="p-column-filter w-[10rem] text-sm" panelStyle={{fontSize: '0.85rem'}} showClear />
  }

  const agencyRowFilterTemplate = (options) => {
    return <Dropdown value={options.value} options={agencies} onChange={(e) => options.filterApplyCallback(e.value)} 
    placeholder="Selecciona una agencia" className="p-column-filter w-[10rem] text-sm" panelStyle={{fontSize: '0.85rem'}} showClear />
  }

  return (
    <>
      { loading ? <div>Loading...</div> : 
        <section className='mx-10 border border-slate-300 my-20'>
          <DataTable value={users} responsiveLayout="scroll" header={header} paginator filters={filters} filterDisplay="row" removableSort
           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
           currentPageReportTemplate="Mostrando del {first} al {last} de un total de {totalRecords} registros" rows={10} rowsPerPageOptions={[10,20,50]} style={{fontSize: '0.85rem'}}>
            <Column field='marca' header='Marca' sortable showFilterMenu={false} filter filterElement={brandRowFilterTemplate} />
            <Column field='agencia' header='Agencia' sortable showFilterMenu={false} filter filterElement={agencyRowFilterTemplate} />
            <Column field='name' header='Nombre' sortable filter filterPlaceholder="Buscar por Nombre" showFilterMenu={false} />
            <Column field='email' header='Correo Electrónico' sortable />
            <Column field='telefono' header='Teléfono' sortable />
            <Column field='cotizaciones' header='Cotizaciones' sortable />
          </DataTable>
        </section>
      }
    </>
  )
}