import { Link } from 'wouter'
import Header from '../../components/Header'

/*Pagina por defecto, si no se encuentra la ruta*/
export default function Notfound () {

  return (
    <>
      <Header />
      <div className='mx-auto my-20 text-center'>
        <h1 className='font-semibold text-blue-700 text-center text-4xl'>404 No se encontro la Pagina Buscada</h1>
        <Link to='/'><button className='border rounded-md bg-blue-600 hover:bg-blue-800 text-white py-1 px-5 my-20 text-xl'>Regresar al Inicio</button></Link>
      </div>
    </>
  )
}