import { useState } from "react";

export function useLocalStorage(key, initialValue){
    /**Actualizar el local storage desde adentro */
    const [storedValue, setStoredValue] = useState(()=>{
        try {
            const item = window.localStorage.getItem(key)
            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            return initialValue
        }
    })


    /**actualizar el local storage desde afuera */
    const setValue = value =>{
        try {
            setStoredValue(value)
         
            window.localStorage.setItem(key, JSON.stringify(value))
          } catch (error) {
            console.log(error)
          }
    }
    return [storedValue, setValue]
}