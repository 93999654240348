import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useState } from 'react'
import { useEffect } from 'react'

import 'primereact/resources/themes/lara-light-blue/theme.css'
import 'primereact/resources/primereact.min.css'                  //core css
import 'primeicons/primeicons.css'

export default function DataTableC () {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  
  useEffect( () => {
    setLoading(true)
    fetch('https://jsonplaceholder.typicode.com/users')
      .then(res => res.json())
      .then(result => setUsers(result))
    setLoading(false)
  }, [users])

  const header = (
    <div className='table-header text-center'>Tabla de Prueba</div>
  )

  return (
    <>
      { loading ? <div>Loading...</div> : 
        <section className='mx-10 border border-slate-300 my-20'>
          <DataTable value={users} responsiveLayout="scroll" header={header} paginator
           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}>
            <Column field='id' header='ID' sortable></Column>
            <Column field='name' header='Nombre' sortable></Column>
            <Column field='username' header='Nombre de Usuario' sortable></Column>
            <Column field='email' header='Correo Electronico' sortable></Column>
            <Column field='phone' header='Telefono' sortable></Column>
            <Column field='address.street' header='Calle' sortable></Column>
          </DataTable>
        </section>
      }
    </>
  )
}