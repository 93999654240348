import { useState, useEffect, useRef } from 'react'
import { Link } from 'wouter'
import Cotizador from '../../components/Cotizador'
import DataTableC from '../../components/DataTable'
import AutosTable from '../../components/DataTable/AutosTable'
import InsuranceTable from '../../components/DataTable/InsuranceTable'
import AddUser from '../../components/AddUser'
import UsersTable from '../../components/DataTable/UsersTable'
import ChartComponent from '../../components/Chart'
import AdminCount from '../../components/AdminCount/AdminVendedor'
import Dashboard from '../../components/Dashboard'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useLogin } from '../../hooks/useLogin'
import { Tooltip } from 'primereact/tooltip'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { putUserUrl, version_Movex } from '../../helpers/helper'
import { Toast } from 'primereact/toast'


/*Supervisor Page*/
export default function Supervisor({ isLogged }) {
  const profile = useRef()
  const toast = useRef(null)
  const [vendedor, setVendedor] = useState(false)
  const [reportes, setReportes] = useState(false)
  const [btnStatus, setBtnStatus] = useState([true, false, false, false, false, false, false, false, false, false, false])
  const { setRoute, setUser, user, component, setComponent } = useLogin()

  useEffect(() => {
    setUser(isLogged)
    setRoute('/supervisor')
  }, [isLogged, setRoute, setUser])

  const showInfo = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 })
  }

  const handlerSubmit = (e) => {
    e.preventDefault()
    profile.current.toggle(false)
    let updateUser = {}
    let nombres = '', apellido_materno = '', apellido_paterno = '', contrasenia = ''
    nombres = (document.getElementById('nombres').value !== undefined && document.getElementById('nombres').value !== null && document.getElementById('nombres').value !== '')
      ? document.getElementById('nombres').value : user.nombres
    apellido_paterno = (document.getElementById('apellidoPaterno').value !== undefined && document.getElementById('apellidoPaterno').value !== null && document.getElementById('apellidoPaterno').value !== '')
      ? document.getElementById('apellidoPaterno').value : user.apellido_paterno
    apellido_materno = (document.getElementById('apellidoMaterno').value !== undefined && document.getElementById('apellidoMaterno').value !== null && document.getElementById('apellidoMaterno').value !== '')
      ? document.getElementById('apellidoMaterno').value : user.apellido_materno
    contrasenia = (document.getElementById('contrasenia').value !== undefined && document.getElementById('contrasenia').value !== null && document.getElementById('contrasenia').value !== '')
      ? document.getElementById('contrasenia').value : ''
    contrasenia === '' ? updateUser = { id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno } :
      updateUser = { id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno, contrasenia: contrasenia }
    console.log(updateUser)

    fetch(putUserUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUser),
    }).then((res) => res.json())
      .then((result) => {
        result.message === 'Success' && showInfo('Los datos fueron actualizados.')
      })
  }

  return (
    <>
      <Toast ref={toast} />
      <Tooltip target='.home' content={`Inicio`} />
      <Tooltip target='.profile' content={`Editar Perfil`} />

      <OverlayPanel ref={profile} showCloseIcon id='overlay_panel' style={{ backgroundColor: '#f3f4f6' }}>
        <form className='px-4 pb-4 p-fluid' onSubmit={handlerSubmit}>
          <h2 className='mb-4 text-lg font-semibold text-center'>Actualizar Datos del Perfil</h2>
          <InputText id='nombres' defaultValue={user.nombres} style={{ height: '2rem', margin: '0.5rem 0' }}
            className="block w-full my-4 text-left text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md sm:text-sm" />
          <InputText id='apellidoPaterno' defaultValue={user.apellido_paterno} style={{ height: '2rem', margin: '0.5rem 0' }}
            className="block w-full my-4 text-left text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md sm:text-sm" />
          <InputText id='apellidoMaterno' defaultValue={user.apellido_materno} style={{ height: '2rem', margin: '0.5rem 0' }}
            className="block w-full my-4 text-left text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md sm:text-sm" />
          <Password inputId='contrasenia' placeholder='Contraseña' toggleMask inputStyle={{ height: '2rem' }}
            className="block w-full text-left text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none rounded-t-md sm:text-sm" />
          <button className='w-full py-2 my-5 text-white bg-indigo-500 rounded-lg hover:bg-indigo-600'>Actualizar</button>
        </form>
      </OverlayPanel>

      <section className='flex flex-row min-h-screen'>
        <aside className='sticky top-0 w-64 h-screen bg-amber-400 ring-indigo-900'
          style={{
            clipPath: 'polygon(0 0, 80% 0, 100% 5%, 100% 95%, 80% 100%, 0 100%)',
            border: '0px solid rebeccapurple', // Aplica el borde sólido
          }}
        >
          {/* <Link to='#'><h2 className='my-10 text-xl font-semibold text-center text-gray-100'>MOVEX MX</h2></Link> */}
          <Link to='#'><img src='imagotipo.png' alt='logoMovex' className='lg:w-[5.5rem] md:w-[4.5rem] w-[3rem] mx-auto my-10' /></Link>
          <div className='my-20 ml-3'>
            {/* <button className={ btnStatus[0] ? 'text-lg text-gray-900 px-5 py-1 bg-white w-full text-start rounded-l-full my-2' : 
            'text-lg text-white px-5 py-1 hover:bg-white hover
            :text-gray-900 w-full text-start rounded-l-full my-2' } onClick={() => {
             setComponent('dashboard')
             const arr = Array(8).fill(false)
             arr[0] = true
             setBtnStatus(arr) 
            }}><i className='mr-2 pi pi-home'></i> Inicio</button> */}
            <button className={btnStatus[7] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-2' :
              'text-lg text-black px-5 py-1 hover:bg-gray-700 hover:text-white w-full text-start rounded-l-full my-2'} onClick={() => {
                setComponent('cotizador')
                const arr = Array(8).fill(false)
                arr[7] = true
                setBtnStatus(arr)
              }}><i className='mr-2 pi pi-money-bill'></i> Cotizador</button>


            <button className={btnStatus[8] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-2'
              : 'text-lg text-black px-5 py-1 hover:bg-gray-700 hover:text-white w-full text-start rounded-l-full my-2'}
              onClick={() => {
                setReportes(!reportes)
                setVendedor(false)
                const arr = Array(11).fill(false)
                arr[8] = true
                setBtnStatus(arr)
              }}
            ><i className='mr-2 pi pi-pencil'></i> Reportes</button>
            {
              reportes &&
              <div className='mb-4'>
                <button className={btnStatus[9] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                  : 'w-full mt-3 block text-black pl-10 pr-10 py-1 hover:bg-gray-700 hover:text-white rounded-l-full text-start'}
                  onClick={() => {
                    setComponent('carTable')
                    const arr = Array(11).fill(false)
                    arr[9] = true
                    setBtnStatus(arr)
                  }}>Autos</button>
                <button className={btnStatus[10] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                  : 'w-full mt-3 block text-black pl-10 pr-10 py-1 hover:bg-gray-700 hover:text-white rounded-l-full text-start'}
                  onClick={() => {
                    setComponent('insuranceTable')
                    const arr = Array(11).fill(false)
                    arr[10] = true
                    setBtnStatus(arr)
                  }}>Seguros</button>
              </div>
            }

            <button className={btnStatus[1] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-2' :
              'text-lg text-black px-5 py-1 hover:bg-gray-700 hover:text-white w-full text-start rounded-l-full my-2'} onClick={() => {
                setVendedor(!vendedor)
                const arr = Array(8).fill(false)
                arr[1] = true
                setBtnStatus(arr)
              }}
            ><i className='mr-2 pi pi-user'></i> Asesores</button>
            {vendedor &&
              <div className='mb-4'>
                <button className={btnStatus[2] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                  : 'w-full mt-3 block text-black pl-10 pr-10 py-1 hover:bg-gray-700 hover:text-white rounded-l-full text-start'}
                  onClick={() => {
                    setComponent('datos_vendedor')
                    const arr = Array(8).fill(false)
                    arr[2] = true
                    setBtnStatus(arr)
                  }}>Reportes</button>
                {/* <button className={ btnStatus[3] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-black pl-10 pr-10 py-1 hover:bg-gray-700 hover:text-white rounded-l-full text-start' } 
                onClick={() => {
                  setComponent('cotizaciones')
                  const arr = Array(8).fill(false)
                  arr[3] = true
                  setBtnStatus(arr) 
                }}>Cotizaciones</button> */}
                {/* <button className={ btnStatus[4] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start' 
                : 'w-full mt-3 block text-black pl-10 pr-10 py-1 hover:bg-gray-700 hover:text-white rounded-l-full text-start' } 
                onClick={() => {
                  setComponent('ventas')
                  const arr = Array(8).fill(false)
                  arr[4] = true
                  setBtnStatus(arr) 
                }}>Cotizaciones - Ventas</button> */}
                <button className={btnStatus[5] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                  : 'w-full mt-3 block text-black pl-10 pr-10 py-1 hover:bg-gray-700 hover:text-white rounded-l-full text-start'}
                  onClick={() => {
                    setComponent('adminCount')
                    const arr = Array(8).fill(false)
                    arr[5] = true
                    setBtnStatus(arr)
                  }}>Administrar Cuenta</button>
                <button className={btnStatus[6] ? 'w-full mt-3 block text-gray-100 pl-10 pr-10 py-1 bg-gray-700 rounded-l-full text-start'
                  : 'w-full mt-3 block text-black pl-10 pr-10 py-1 hover:bg-gray-700 hover:text-white rounded-l-full text-start'}
                  onClick={() => {
                    setComponent('addUser')
                    const arr = Array(8).fill(false)
                    arr[6] = true
                    setBtnStatus(arr)
                  }}>Agregar Cuenta</button>
              </div>
            }
          </div>

          <div className='absolute inset-x-0 bottom-0 my-10 mr-3'>
            <Link to='/logout' className='w-full px-10 py-1 my-2 text-lg text-black rounded-r-full hover:bg-gray-600 hover:text-white text-end' onClick={() => {
              setRoute('/')
              setUser({})
            }}>
              <i className='mr-2 pi pi-sign-out'></i> Cerrar Sesión
            </Link>
            {/* <p className='text-black pl-10 pt-5 text-[0.75rem]'>Cotizador Movex v.1.3.2 by BMTools</p> */}
            <p className='text-black pl-10 pt-5 text-[0.75rem]'>{version_Movex}</p>
          </div>
        </aside>

        <div className='w-10/12'>
          <div className='flex justify-start mx-10 my-5'>
            <button className='mr-5 text-indigo-700 border-2 border-indigo-700 rounded-lg hover:bg-indigo-50 home' onClick={() => {
              setComponent('dashboard')
              setBtnStatus([true, false, false, false, false, false, false, false, false, false, false])
            }}>
              <i className='p-2 pi pi-home' style={{ 'fontSize': '1.6rem' }}></i></button>
            <button className='text-indigo-700 border-2 border-indigo-700 rounded-lg hover:bg-indigo-50 profile' onClick={(e) => profile.current.toggle(e)}>
              <i className='p-2 pi pi-user-edit' style={{ 'fontSize': '1.6rem' }}></i></button>
          </div>
          {
            component === 'dashboard' ? <Dashboard />
              : component === 'datos_vendedor' ? <UsersTable tipo='vendedor' title='Asesores' />
                : component === 'cotizaciones' ? <DataTableC />
                  : component === 'ventas' ? <ChartComponent />
                    : component === 'adminCount' ? <AdminCount tipo='vendedor' />
                      : component === 'addUser' ? <AddUser type='Vendedor' />
                        : component === 'cotizador' ? <Cotizador />
                          : component === 'carTable' ? <AutosTable />
                            : component === 'insuranceTable' ? <InsuranceTable />
                              : <DataTableC />
          }
        </div>
      </section>
    </>
  )
}