import { useState, useEffect, useRef } from 'react'
import { Link } from 'wouter'
import UploadFile from '../../components/UploadFile'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useLogin } from '../../hooks/useLogin'
import { Tooltip } from 'primereact/tooltip'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { putUserUrl,version_Movex } from '../../helpers/helper'
import { Toast } from 'primereact/toast'

//Broker Page
export default function Admin ( {isLogged} ) {
  const profile = useRef()
  const toast = useRef(null)
  const [component, setComponent] = useState('uploadAutos')
  const [btnStatus, setBtnStatus] = useState([false, false, false])
  const { setRoute, setUser, user } = useLogin()

  useEffect(() => {
    setUser(isLogged)
    setRoute('/broker')
  }, [isLogged, setRoute, setUser])

  const showInfo = (message) => {
    toast.current.show({severity:'success', summary: 'Success', detail: message, life: 3000})
  }

  const handlerSubmit = (e) => {
    e.preventDefault()
    profile.current.toggle(false)
    let updateUser = {}
    let nombres = '', apellido_materno = '', apellido_paterno = '', contrasenia = ''
    nombres = ( document.getElementById('nombres').value !== undefined && document.getElementById('nombres').value !== null && document.getElementById('nombres').value !== '') 
    ? document.getElementById('nombres').value : user.nombres
    apellido_paterno = ( document.getElementById('apellidoPaterno').value !== undefined && document.getElementById('apellidoPaterno').value !== null && document.getElementById('apellidoPaterno').value !== '' ) 
    ? document.getElementById('apellidoPaterno').value : user.apellido_paterno
    apellido_materno = ( document.getElementById('apellidoMaterno').value !== undefined && document.getElementById('apellidoMaterno').value !== null && document.getElementById('apellidoMaterno').value !== '' ) 
    ? document.getElementById('apellidoMaterno').value : user.apellido_materno
    contrasenia = ( document.getElementById('contrasenia').value !== undefined && document.getElementById('contrasenia').value !== null && document.getElementById('contrasenia').value !== '' ) 
    ? document.getElementById('contrasenia').value : ''
    contrasenia === '' ? updateUser = {id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno } :
    updateUser = {id: user.id, nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno, contrasenia: contrasenia }
    console.log(updateUser)

    fetch(putUserUrl, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUser),
    }).then((res) => res.json())
      .then((result) => {
        result.message === 'Success' && showInfo('Los datos fueron actualizados.')
      })
  }

  return(
    <>
      <Toast ref={toast} />
      <Tooltip target='.profile' content={`Editar Perfil`} position='left'/>

      <OverlayPanel ref={profile} showCloseIcon id='overlay_panel' style={{backgroundColor: '#f3f4f6'}}>
        <form className='px-4 pb-4 p-fluid' onSubmit={handlerSubmit}>
          <h2 className='font-semibold mb-4 text-center text-lg'>Actualizar Datos del Perfil</h2>
          <InputText id='nombres' defaultValue={user.nombres} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <InputText id='apellidoPaterno' defaultValue={user.apellido_paterno} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <InputText id='apellidoMaterno' defaultValue={user.apellido_materno} style={{height: '2rem', margin: '0.5rem 0'}}
          className="my-4 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <Password inputId='contrasenia' placeholder='Contraseña' toggleMask inputStyle={{height: '2rem'}}
          className="text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
          <button className='my-5 bg-indigo-500 text-white py-2 w-full rounded-lg hover:bg-indigo-600'>Actualizar</button>
        </form>
      </OverlayPanel>

      <div className='flex flex-row'>
        <aside className='sticky top-0 h-screen w-64 bg-gray-900 rounded-r-[2.4rem] ring-8 ring-indigo-300'>
          {/* <Link to='#'><h2 className='text-gray-100 font-semibold text-xl text-center my-10'>MOVEX MX</h2></Link> */}
          <Link to='#'><img src='imagotipo.png' alt='logoMovex' className='lg:w-[5.5rem] md:w-[4.5rem] w-[3rem] mx-auto my-10' /></Link>
          <div className='my-20 ml-3'>
            {/* <button className={ btnStatus[0] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-2 truncate' 
            : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-2 truncate' } 
            onClick={() => {
              setComponent('uploadAutos')
              const arr = Array(3).fill(false)
              arr[0] = true
              setBtnStatus(arr)  
            }}><i className='pi pi-cloud-upload mr-2'></i> Subir Archivo Autos</button> */}
            <button className={ btnStatus[1] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-2 truncate' 
            : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-2 truncate' } 
            onClick={() => {
              setComponent('uploadSeguros')
              const arr = Array(3).fill(false)
              arr[1] = true
              setBtnStatus(arr)  
            }}><i className='pi pi-cloud-upload mr-2'></i> Subir Archivo Seguros</button>
            {/* <button className={ btnStatus[2] ? 'text-lg text-white px-5 py-1 bg-gray-700 w-full text-start rounded-l-full my-2 truncate' 
            : 'text-lg text-white px-5 py-1 hover:bg-gray-700 w-full text-start rounded-l-full my-2 truncate' } 
            onClick={() => {
              setComponent('uploadAgencias')
              const arr = Array(3).fill(false)
              arr[2] = true
              setBtnStatus(arr)  
            }}><i className='pi pi-cloud-upload mr-2'></i> Subir Archivo Agencias</button> */}
          </div>
          <div className='absolute inset-x-0 bottom-0 my-10 mr-3'>
            <Link to='/logout' className='text-lg text-white px-10 py-1 hover:bg-gray-600 w-full text-end rounded-r-full my-2' onClick={() => {
              setRoute('/')
              setUser({})
            }}>
             <i className='pi pi-sign-out mr-2'></i> Cerrar Sesión
            </Link>
            {/* <p className='text-white pl-10 pt-5 text-[0.75rem]'>Cotizador Movex v.1.3.2 by BMTools</p> */}
            <p className='text-white pl-10 pt-5 text-[0.75rem]'>{version_Movex}</p>
          </div>
        </aside>

        <section className='mx-auto w-5/6'>
          <div className='mx-10 my-5 flex justify-end'>
            <button className='border-2 text-indigo-700 border-indigo-700 hover:bg-indigo-50 rounded-lg profile' onClick={(e) => profile.current.toggle(e)}>
              <i className='pi pi-user-edit p-2' style={{'fontSize': '1.6rem'}}></i></button>
          </div>
         {
            component === 'uploadAutos' ? <UploadFile label='Autos' />
            : component === 'uploadSeguros' ? <UploadFile label='Seguros' />
            : <UploadFile label='Agencias' />
         }
        </section>
      </div>
    </>
  )
}