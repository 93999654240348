import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Password } from 'primereact/password'
import { useState, useEffect, useRef } from 'react'
import { urlApi } from '../../helpers/helper'
import { Toast } from 'primereact/toast'
import { putUserUrl, getAgency } from '../../helpers/helper'
import AdminTable from '../DataTable/AdminTable'

//Selector de marca
function BrandSelector ( {brand, agencyData, setAgency, setAgencyState, updateUser} ) {
  return (
    <>
      <select id="marca" className="mt-2 w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 col-span-2
      focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm" 
      onClick={(e) => {
        let result = []
        agencyData.map(el => {
          el.marca === e.target.value && result.push(el.nombre)
          return null
        })
        setAgency(result)
        setAgencyState(true)
      }}>
        <option value={updateUser.marca}>{updateUser.marca}</option>
        { brand.map(el => el !== updateUser.marca && (<option key={el} value={el}>{el}</option>) ) }
      </select>
    </>
  )
}

//Selector de Agencia
function AgencySelector ( {agency, agencyState, updateUser} ){
  return (
    <select id="agencia" placeholder='Agencia' disabled={!agencyState} className="mt-2 col-span-2 w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 
    placeholder-gray-500 focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm">
      <option value=''>Seleccionar Agencia</option>
      { agency.map(el => ( <option key={el} value={el}>{el}</option>)) }
    </select>
  )
}

export default function AdminVendedor ( {tipo} ) {
  const [ users, setUsers ] = useState([])
  const [ trigger, setTrigger ] = useState(false)
  const [ updateUser, setUpdateUser ] = useState({})
  const [ password, setPassword ] = useState('')
  const [ options ] = useState( [{label: 'Activo', value: 1}, {label: 'Inactivo', value: 0}] )
  const toast = useRef(null)

  const [brand, setBrand] = useState([])
  const [agencyData, setAgencyData] = useState([])
  const [agency, setAgency] = useState([])
  const [agencyState, setAgencyState] = useState(false)

  useEffect( () => {
    fetch(`${urlApi}/get/user/q=${tipo}`)
      .then(res => res.json())
      .then(result => {
        result.map(el => {
          el.name = `${el.nombres} ${el.apellido_paterno} ${el.apellido_materno}`
          el.privilegios === 1 ? el.privilegios = 'Activo' : el.privilegios = 'Inactivo'
          return null
        })
        setUsers(result)
      })
  }, [tipo, trigger])

  useEffect( () => {
    fetch(getAgency).then(res => res.json())
      .then(data => {
        let marca = []
        let agencia = []
        setAgencyData(data)
        data.map(el => {
          marca.push(el.marca)
          agencia.push(el.nombre)
          return null
        })
        setBrand([...new Set(marca)])
        setAgency([...new Set(agencia)])
      })
  }, [])

  const handlerSubmit = (e) => {
    e.preventDefault()
    const nombres = document.getElementById('nombres').value
    const apellido_paterno = document.getElementById('paterno').value
    const apellido_materno = document.getElementById('materno').value
    const email = document.getElementById('email').value
    const telefono = document.getElementById('telefono').value
    const privilegios = updateUser.privilegios === 'Activo' ? true : false
    const agencia = document.getElementById('agencia').value
    const marca = document.getElementById('marca').value
    
    const data = password !== '' ? {id: updateUser.id, nombres, apellido_paterno, apellido_materno, email, telefono, privilegios, agencia, marca, contrasenia: password}
    : {id: updateUser.id, nombres, apellido_paterno, apellido_materno, email, telefono, privilegios, agencia, marca} 
    //console.log(data)

    fetch(putUserUrl, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => res.json())
      .then((result) => {
        result.message === 'Success' && showInfo('El usuario fue actualizado correctamente')
        setUpdateUser({})
        setPassword('')
        setTrigger(!trigger)
      })
  }

  const showInfo = (message) => {
    toast.current.show({severity:'success', summary: 'Success', detail: message, life: 3000})
  }

  return (
    <> 
      <Toast ref={toast} />
      <div className='mx-auto w-11/12 mt-20 p-5'>
        <h1 className='text-2xl font-semibold'>Administrar Cuenta</h1>
          <div className='grid grid-cols-6 gap-10'>
            <section className='lg:col-span-4 md:col-span-6 sm:col-span-6 col-span-6'>
              {Object.keys(users).length > 0 ? <AdminTable data={users} setUpdateUser={setUpdateUser} /> : <div>Loading...</div> }
            </section>
            { Object.keys(updateUser).length > 0 ?
              <form className='my-5 px-4 py-4 border rounded-lg text-center col-span-2 p-fluid shadow-md lg:col-span-2 md:col-span-6 sm:col-span-6 col-span-6' onSubmit={handlerSubmit}>
                <h2 className='text-center font-semibold text-xl my-5' >Actualizar Datos</h2>
                <div className='grid grid-cols-3'>
                  <label className='mt-[1rem] py-[0.5rem] px-2 text-left' htmlFor="nombres">Nombres</label>
                  <InputText id='nombres' style={{margin: '1rem 0 0.4rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="col-span-2 relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" 
                  defaultValue={updateUser.nombres} />
                </div>
                <div className='grid grid-cols-3'>
                  <label className='mt-[0.4rem] py-[0.5rem] px-2 text-left' htmlFor="paterno">Apellido Paterno</label>
                  <InputText id='paterno' style={{margin: '0.4rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="col-span-2 relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" 
                  defaultValue={updateUser.apellido_paterno} />
                </div>
                <div className='grid grid-cols-3'>
                  <label className='mt-[0.4rem] py-[0.5rem] px-2 text-left truncate' htmlFor="materno">Apellido Materno</label>
                  <InputText id='materno' style={{margin: '0.4rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="col-span-2 relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" 
                  defaultValue={updateUser.apellido_materno} />
                </div>
                <div className='grid grid-cols-3'>
                  <label className='mt-[0.4rem] py-[0.5rem] px-2 text-left truncate' htmlFor="email">Correo Electrónico</label>
                  <InputText id='email' style={{margin: '0.4rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="col-span-2 relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" 
                  defaultValue={updateUser.email} />
                </div>
                <div className='grid grid-cols-3'>
                  <label className='mt-[0.4rem] py-[0.5rem] px-2 text-left' htmlFor="telefono">Telefono</label>
                  <InputText id='telefono' style={{margin: '0.4rem 0', fontSize: '0.85rem', padding: '.5rem 1rem'}} className="col-span-2 relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" 
                  defaultValue={updateUser.telefono} type='tel' />
                </div>
                <div className='grid grid-cols-3'>
                  <label className='mt-[0.4rem] py-[0.5rem] px-2 text-left' htmlFor="marca">Marca</label>
                  <BrandSelector brand={brand} agencyData={agencyData} setAgency={setAgency} setAgencyState={setAgencyState} updateUser={updateUser} />
                </div>
                <div className='grid grid-cols-3'>
                  <label className='mt-[0.4rem] py-[0.5rem] px-2 text-left' htmlFor="agencia">Agencia</label>
                  <AgencySelector agency={agency} agencyState={agencyState} updateUser={updateUser} />
                </div>
                <div className='grid grid-cols-3'>
                  <label className='mt-4 py-[0.5rem] px-2 text-left' htmlFor='privilegios'>Privilegios</label>
                  <Dropdown id='privilegios' options={options} value={updateUser.privilegios === 'Activo' ? 1 : 0} onChange={(e) => setUpdateUser({...updateUser, privilegios: e.value === 0 ? 'Inactivo' : 'Activo'})}
                  className="col-span-2 mt-2 relative text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
                </div>
                <div className='grid grid-cols-3'>
                  <label className='mt-2 pt-4 px-2 text-left' htmlFor="contrasenia">Contraseña</label>
                  <Password id='contrasenia' value={password} onChange={(e) => setPassword(e.target.value)} toggleMask
                  className="col-span-2 mt-2 text-left block w-full rounded-none rounded-t-md border border-gray-300 text-gray-900 placeholder-gray-500 sm:text-sm" />
                </div>

                <button className='mt-4 mb-5 bg-indigo-500 text-white px-5 py-2 rounded-lg hover:bg-indigo-600 hover:ring hover:ring-indigo-200'>Actualizar Usuario</button>
              </form> : <div></div>
            }
          </div>
      </div>
    </>
  )
}