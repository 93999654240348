import { useRef } from 'react'
import { Toast } from 'primereact/toast'
import { Password } from 'primereact/password'
import { addUserUrl } from '../../helpers/helper'
import { useState, useEffect } from 'react'
import { getAgency } from '../../helpers/helper'

//Selector de marca
function BrandSelector({ brand, agencyData, setAgency, setAgencyState }) {
  return (
    <>
      <select id="brandSelector" className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm"
        onClick={(e) => {
          let result = []
          agencyData.map(el => {
            el.marca === e.target.value && result.push(el.nombre)
            return null
          })
          setAgency(result)
          setAgencyState(true)
        }}>
        <option value=''>Ninguna Marca Seleccionada</option>
        {brand.map(el => (<option key={el} value={el}>{el}</option>))}
      </select>
    </>
  )
}

//Selector de Agencia
function AgencySelector({ agency, agencyState }) {
  return (
    <select id="agencySelector" placeholder='Agencia' disabled={!agencyState} className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:z-10 focus:border-indigo-400 focus:outline-none focus:ring-4 focus:ring-indigo-200 hover:border-indigo-400 sm:text-sm">
      <option value=''>Ninguna Agencia Seleccionada</option>
      {agency.map(el => (<option key={el} value={el}>{el}</option>))}
    </select>
  )
}

//Componente que se encargara de mostrar wl login para dar de alta un usuario
export default function AddUser({ type }) {
  const toast = useRef(null)
  const [brand, setBrand] = useState([])
  const [agencyData, setAgencyData] = useState([])
  const [agency, setAgency] = useState([])
  const [agencyState, setAgencyState] = useState(false)

  useEffect(() => {
    fetch(getAgency).then(res => res.json())
      .then(data => {
        let marca = []
        let agencia = []
        setAgencyData(data)
        data.map(el => {
          marca.push(el.marca)
          agencia.push(el.nombre)
          return null
        })
        setBrand([...new Set(marca)])
        setAgency([...new Set(agencia)])
      })
  }, [])

  const showInfo = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 })
  }

  const handlerSubmit = (e) => {
    e.preventDefault()
    const nombres = document.getElementById('name').value
    const apellido_paterno = document.getElementById('lastnamep').value
    const apellido_materno = document.getElementById('lastnamem').value
    const brand = document.getElementById('brandSelector').value
    const agencia = document.getElementById('agencySelector').value
    const tipo = type.toLowerCase()
    const email = document.getElementById('email').value
    const telefono = document.getElementById('phone').value
    const password = document.getElementById('password').value
    const obj = {
      nombres: nombres, apellido_paterno: apellido_paterno, apellido_materno: apellido_materno, email: email, telefono: telefono,
      estatus: false, privilegios: true, contrasenia: password, tipo: tipo, cotizaciones: 0, agencia: agencia, marca: brand
    }

    fetch(addUserUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    }).then((res) => res.json())
      .then((result) => {
        result.message === 'Success' && showInfo('El usuario fue creado correctamente')
      })

    document.getElementById('name').value = ''
    document.getElementById('lastnamep').value = ''
    document.getElementById('lastnamem').value = ''
    document.getElementById('email').value = ''
    document.getElementById('password').value = ''
    document.getElementById('phone').value = ''
    document.getElementById('agencySelector').value = ''
    document.getElementById('brandSelector').value = ''
    setAgencyState(false)
  }

  return (
    <>
      <Toast ref={toast} />
      <section className="flex items-center justify-center px-4 my-10 sm:px-6 lg:px-8 lg:my-10 lg:py-5">
        <div className="w-full max-w-md px-5 py-10 space-y-8 border border-gray-200 rounded-md shadow-md">
          <form className="px-5 py-4 text-center" onSubmit={handlerSubmit}>
            <h2 className="mt-6 mb-10 text-3xl font-bold tracking-tight text-center text-gray-900">Agregar {type === 'Vendedor' ? 'Asesor' : 'Supervisor'}</h2>
            <input required type="text" id="name" placeholder="Nombre(s)" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm" />
            <input required type="text" id="lastnamep" placeholder="Apellido Paterno" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm" />
            <input required type="text" id="lastnamem" placeholder="Apellido Materno" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm" />
            {/* <input type="text" id="brand" placeholder="Marca" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"/> */}
            {/* <input type="text" id="agency" placeholder="Agencia" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"/> */}
            <div className='grid grid-cols-2 gap-x-2'>
              <BrandSelector brand={brand} agencyData={agencyData} setAgency={setAgency} setAgencyState={setAgencyState} />
              <AgencySelector agency={agency} agencyState={agencyState} />
            </div>

            <input required type="email" id="email" placeholder="Correo Electrónico" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm" />
            <input type="tel" id="phone" placeholder="Teléfono" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm" />
            {/* <input required type="password" id="password" placeholder="Contraseña" className="relative block w-full px-3 py-2 my-5 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:z-10 focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"/> */}
            <Password id='contrasenia' inputId="password" placeholder="Contraseña" toggleMask inputClassName='w-full' className='w-full mb-5' inputStyle={{ height: '2.5rem' }} />
            <button className="flex justify-center w-full px-4 py-2 text-sm font-medium text-black border border-transparent rounded-md bg-amber-500 hover:bg-amber-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-amber-700 focus:ring-offset-2">Guardar</button>
          </form>
        </div>
      </section>
    </>
  )
}