import { useEffect, useState, useRef } from 'react'
import { useCotizacion } from '../../hooks/useCotizacion'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'
import { putParametersUrl } from '../../helpers/helper'

export default function UpdateParams() {
  const { parametros } = useCotizacion()
  const [updateData, setUpdateData] = useState({})
  const toast = useRef(null)

  useEffect(() => {
    console.log(parametros)
    parametros.rate > 0 && setUpdateData({
      tasa: parametros.rate, localizador24: parametros.localizador[0],
      localizador36: parametros.localizador[1], localizador48: parametros.localizador[2], comision1: parametros.comision[0],
      comision2: parametros.comision[1], comision3: parametros.comision[2], comision4: parametros.comision[3], comision5: parametros.comision[4],
      limite1: parametros.limite[0], limite2: parametros.limite[1],
      limite3: parametros.limite[2], limite4: parametros.limite[3]
    })
  }, [parametros])

  const showInfo = (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 })
  }

  const handlerSubmit = (e) => {
    e.preventDefault()
    console.log(updateData)
    // return
    fetch(putParametersUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    }).then((res) => res.json())
      .then((result) => {
        result.message === 'Success' && showInfo('Los parametros fueron actualizados.')
      })
  }

  return (
    <>


      <Toast ref={toast} />
      <section className='w-full p-5 mx-auto border rounded-md shadow-md lg:w-5/12 md:w-10/12 sm:w-full lg:my-10 bg-gray-50'>
        <form className='px-4 pb-4 mt-5 p-fluid' onSubmit={handlerSubmit}>
          <h2 className='mb-10 text-2xl font-semibold text-center'>Actualización de Parámetros.</h2>
          <div className='grid grid-cols-5'>
            <label htmlFor='percentParam' className='col-span-2 ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Tasa</label>
            {/* <InputNumber inputId="percentParam" value={updateData.tasa * 100} suffix="%" style={{height: '2rem', margin: '0.5rem 0'}} className='col-span-3'
            inputStyle={{textAlign: 'right', padding: '0 1rem'}} onValueChange={(e) => setUpdateData({...updateData, tasa: e.value/100})}/> */}
            <InputNumber
              inputId="percentParam"
              value={updateData.tasa * 100}
              suffix="%"
              style={{ height: '2rem', margin: '0.5rem 0' }}
              className='col-span-3'
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }}
              onValueChange={(e) => setUpdateData({ ...updateData, tasa: e.value / 100 })}
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}

            />

          </div>
          <h3 className='mt-3 font-semibold text-center text-slate-500'>Localizador</h3>
          <section className='grid grid-cols-3 mb-3 gap-x-1'>
            <label htmlFor='localizador24' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>24 Meses</label>
            <label htmlFor='localizador36' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>36 Meses</label>
            <label htmlFor='localizador48' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>48 Meses</label>

            <InputNumber inputId="localizador24" value={updateData.localizador24} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, localizador24: e.value })}
            />
            <InputNumber inputId="localizador36" value={updateData.localizador36} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, localizador36: e.value })}
            />
            <InputNumber inputId="localizador48" value={updateData.localizador48} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, localizador48: e.value })}
            />
          </section>
          <div>
            <h3 className='font-semibold text-center'>Comisión por apertura</h3>
            <p className='p-2 text-sm text-justify'>La comisión por apertura se fijará en función de los limites{` (Límite 1 y Límite 2) `}conforme a lo siguiente: </p>
            <ul>
              <li className='px-2 text-sm text-justify'>1. Sí el precío del vehículo es menor o igual al "Límite 1. entonces se tomará el valor asignado en la comisión
                número uno "Comisión 1".</li>
              <li className='px-2 text-sm text-justify'>2. Sí el precío del vehículo es mayor al "Límite 1" y menor al "Límite 2", entonces se tomará el valor asignado en la
                comsión número dos "Comisión 2".</li>
              <li className='px-2 text-sm text-justify'>3. Y así sucesivamente.</li>
            </ul>
          </div>
          <section className='grid grid-cols-3 gap-x-1'>
            <label htmlFor='limite0' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Límite Inf.</label>
            <label htmlFor='limite1' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Límite Sup.</label>
            <label htmlFor='comision1' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Comisión </label>

            <InputNumber value="1" mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} readOnly
            />
            <InputNumber inputId="limite1" value={updateData.limite1} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, limite1: e.value })}
            />
            <InputNumber inputId="comision1" value={updateData.comision1} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, comision1: e.value })}
            />
            {/* <label htmlFor='limite1' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Límite 1</label>
            <label htmlFor='limite2' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Límite 2</label>
           
            <InputNumber inputId="limite1" value={updateData.limite1} mode="currency" currency="USD" locale="en-US" style={{height: '2rem', margin: '0.5rem 0'}}
            inputStyle={{textAlign: 'right', padding: '0 1rem'}} onValueChange={(e) => setUpdateData({...updateData, limite1: e.value})}
            />
            <InputNumber inputId="limite2" value={updateData.limite2} mode="currency" currency="USD" locale="en-US" style={{height: '2rem', margin: '0.5rem 0'}}
            inputStyle={{textAlign: 'right', padding: '0 1rem'}} onValueChange={(e) => setUpdateData({...updateData, limite2: e.value})}
            /> */}

          </section>
          <section className='grid grid-cols-3 gap-x-1'>

            <InputNumber value={(updateData.limite1 + 1)} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} readOnly
            />
            <InputNumber inputId="limite2" value={updateData.limite2} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, limite2: e.value })}
            />
            <InputNumber inputId="comision2" value={updateData.comision2} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, comision2: e.value })}
            />

          </section>
          <section className='grid grid-cols-3 gap-x-1'>

            <InputNumber value={updateData.limite2 + 1} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} readOnly
            />
            <InputNumber inputId="limite3" value={updateData.limite3} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, limite3: e.value })}
            />
            <InputNumber inputId="comision3" value={updateData.comision3} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, comision3: e.value })}
            />

          </section>
          <section className='grid grid-cols-3 gap-x-1'>
            {/* <label htmlFor='limite3' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Límite 3</label>
            <label htmlFor='limite4' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Límite 4</label> */}
            <InputNumber value={updateData.limite3 + 1} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} readOnly
            />
            <InputNumber inputId="limite4" value={updateData.limite4} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, limite4: e.value })}
            />
            <InputNumber inputId="comision4" value={updateData.comision4} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, comision4: e.value })}
            />
          </section>
          <section className='grid grid-cols-3 gap-x-1'>
            <InputNumber value={updateData.limite4 + 1} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} readOnly
            />
            <InputNumber value="" mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} readOnly
            />

            <InputNumber inputId="comision5" value={updateData.comision5} mode="currency" currency="USD" locale="en-US" style={{ height: '2rem', margin: '0.5rem 0' }}
              inputStyle={{ textAlign: 'right', padding: '0 1rem' }} onValueChange={(e) => setUpdateData({ ...updateData, comision5: e.value })}
            />
          </section>
          {/* <section className='grid grid-cols-3 gap-x-1'>
             <label htmlFor='comision1' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Comisión 1</label>
            <label htmlFor='comision2' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Comisión 2</label>
            <label htmlFor='comision3' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Comisión 3</label>
           
            <InputNumber inputId="comision1" value={updateData.comision1} mode="currency" currency="USD" locale="en-US" style={{height: '2rem', margin: '0.5rem 0'}}
              inputStyle={{textAlign: 'right', padding: '0 1rem'}} onValueChange={(e) => setUpdateData({...updateData, comision1: e.value})}
              />
            <InputNumber inputId="comision2" value={updateData.comision2} mode="currency" currency="USD" locale="en-US" style={{height: '2rem', margin: '0.5rem 0'}}
              inputStyle={{textAlign: 'right', padding: '0 1rem'}} onValueChange={(e) => setUpdateData({...updateData, comision2: e.value})}
              />
            <InputNumber inputId="comision3" value={updateData.comision3} mode="currency" currency="USD" locale="en-US" style={{height: '2rem', margin: '0.5rem 0'}}
              inputStyle={{textAlign: 'right', padding: '0 1rem'}} onValueChange={(e) => setUpdateData({...updateData, comision3: e.value})}
              /> 
          
          </section> */}
          {/* <section className='grid grid-cols-2 gap-x-1'>
            <label htmlFor='comision4' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Comisión 4</label>
            <label htmlFor='comision5' className='ml-[0.5rem] my-[0.5rem] font-semibold text-center text-slate-500'>Comisión 5</label>
          
            <InputNumber inputId="comision4" value={updateData.comision4} mode="currency" currency="USD" locale="en-US" style={{height: '2rem', margin: '0.5rem 0'}}
              inputStyle={{textAlign: 'right', padding: '0 1rem'}} onValueChange={(e) => setUpdateData({...updateData, comision4: e.value})}
              />
            <InputNumber inputId="comision5" value={updateData.comision5} mode="currency" currency="USD" locale="en-US" style={{height: '2rem', margin: '0.5rem 0'}}
              inputStyle={{textAlign: 'right', padding: '0 1rem'}} onValueChange={(e) => setUpdateData({...updateData, comision5: e.value})}
              />
          </section> */}
          <button className='w-full py-2 mt-10 text-black rounded-lg bg-amber-400 hover:bg-amber-600 hover:text-white'>Actualizar</button>
        </form>
      </section>
    </>
  )
}